import Dashboard from 'components/Dashboard/Dashboard'
import loadingIco from 'components/ui/Logo/loading.gif'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'

import iconHome from 'assets/icons/home.svg'
import iconCouple from 'assets/icons/iconCouple.svg'
import iconDashboard from 'assets/icons/iconDashboard.svg'
import iconSingle from 'assets/icons/iconSingle.svg'

export default function DashboardPage() {
  const { setTitle } = useContext(TitleContext)
  const [storeGenders, fetchGenders] = useFetch()
  const [storeTowns, fetchTowns] = useFetch()
  const [storeCouplesGenders, fetchCouplesGrender] = useFetch()
  const [storeDashEntities, fetchDashEntities] = useFetch()

  // Vilafranca
  const [storeVilafrancaAll, fetchVilafrancaAll] = useFetch()
  const [storeVilafrancaCouplesGenders, fetchVilafrancaCouplesGenders] =
    useFetch()
  const [storeVilafrancaSinglesGenders, fetchVilafrancaSinglesGenders] =
    useFetch()
  const [storeDashEntitiesVilafranca, fetchDashEntitiesVilafranca] = useFetch()

  // NotVilafranca
  const [storeNotVilafrancaAll, fetchNotVilafrancaAll] = useFetch()
  const [storeNotVilafrancaCouplesGenders, fetchNotVilafrancaCouplesGenders] =
    useFetch()
  const [storeNotVilafrancaSinglesGenders, fetchNotVilafrancaSinglesGenders] =
    useFetch()
  const [storeDashEntitiesNotVilafranca, fetchDashEntitiesNotVilafranca] =
    useFetch()
  const [statsGenders, setStatsGenders] = useState([])
  const [statsTowns, setStatsTowns] = useState([])
  const [statsCouplesGenders, setStatsCouplesGenders] = useState([])
  const [statsDashEntities, setStatsDashEntities] = useState([])

  // Vilafranca
  const [statsVilafrancaAll, setStatsVilafrancaAll] = useState([])
  const [statsVilafrancaCouplesGenders, setStatsVilafrancaCouplesGenders] =
    useState([])
  const [statsVilafrancaSinglesGenders, setStatsVilafrancaSinglesGenders] =
    useState([])
  const [statsDashEntitiesVilafranca, setStatsDashEntitiesVilafranca] =
    useState([])

  // NotVilafranca
  const [statsNotVilafrancaAll, setStatsNotVilafrancaAll] = useState([])
  const [
    statsNotVilafrancaCouplesGenders,
    setStatsNotVilafrancaCouplesGenders
  ] = useState([])
  const [
    statsNotVilafrancaSinglesGenders,
    setStatsNotVilafrancaSinglesGenders
  ] = useState([])
  const [statsDashEntitiesNotVilafranca, setStatsDashEntitiesNotVilafranca] =
    useState([])

  useEffect(async () => {
    const dashboardTitle = { name: 'Panell - Resum participants', buttons: [] }
    setTitle(dashboardTitle)

    // fetch de dades per etiquetes
    const getGenderAPIParams = {
      url: 'db/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques per Gènere.'
    }
    await fetchGenders(getGenderAPIParams)

    // fetch de dades per etiquetes
    const getSinglesTownAPIParams = {
      url: 'db/singles/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de individuals per Gènere.'
    }
    await fetchTowns(getSinglesTownAPIParams)

    // fetch de dades per etiquetes
    const getCouplesGrenderAPIParams = {
      url: 'db/couples/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere.'
    }
    await fetchCouplesGrender(getCouplesGrenderAPIParams)

    // fetch de dades per etiquetes
    const getDashEntitiesAPIParams = {
      url: 'db/dash/entities/total',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere.'
    }
    await fetchDashEntities(getDashEntitiesAPIParams)

    // Vilafranca
    const getVilafrancaAllAPIParams = {
      url: 'db/VilafrancaGender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques per Gènere Vilafranca.'
    }
    await fetchVilafrancaAll(getVilafrancaAllAPIParams)

    // Couples
    const getVilafrancaCouplesGendersAPIParams = {
      url: 'db/couplesVilafranca/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere Vilafranca.'
    }
    await fetchVilafrancaCouplesGenders(getVilafrancaCouplesGendersAPIParams)

    // Singles
    const getVilafrancaSinglesGendersAPIParams = {
      url: 'db/singlesVilafranca/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere Vilafranca.'
    }
    await fetchVilafrancaSinglesGenders(getVilafrancaSinglesGendersAPIParams)

    const getDashEntitiesVilafrancaAPIParams = {
      url: 'db/dash/entities/vilafranca',
      method: 'GET',
      messageKo: "Error rebent estadistiques d'entitats Vilafranca."
    }
    await fetchDashEntitiesVilafranca(getDashEntitiesVilafrancaAPIParams)

    // NotVilafranca
    const getNotVilafrancaAllAPIParams = {
      url: 'db/NotVilafrancaGender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques per Gènere Vilafranca.'
    }
    await fetchNotVilafrancaAll(getNotVilafrancaAllAPIParams)

    // Couples
    const getNotVilafrancaCouplesGendersAPIParams = {
      url: 'db/couplesNotVilafranca/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere Vilafranca.'
    }
    await fetchNotVilafrancaCouplesGenders(
      getNotVilafrancaCouplesGendersAPIParams
    )

    // Singles
    const getNotVilafrancaSinglesGendersAPIParams = {
      url: 'db/singlesNotVilafranca/gender',
      method: 'GET',
      messageKo: 'Error rebent estadistiques de parella per Gènere Vilafranca.'
    }
    await fetchNotVilafrancaSinglesGenders(
      getNotVilafrancaSinglesGendersAPIParams
    )

    const getDashEntitiesNotVilafrancaAPIParams = {
      url: 'db/dash/entities/comarca',
      method: 'GET',
      messageKo: "Error rebent estadistiques d'entitats Comarca."
    }
    await fetchDashEntitiesNotVilafranca(getDashEntitiesNotVilafrancaAPIParams)
  }, [])

  useEffect(() => {
    if (storeGenders) {
      setStatsGenders(storeGenders.data)
    }
  }, [storeGenders])

  useEffect(() => {
    if (storeTowns) {
      setStatsTowns(storeTowns.data)
    }
  }, [storeTowns])

  useEffect(() => {
    if (storeCouplesGenders) {
      setStatsCouplesGenders(storeCouplesGenders.data)
    }
  }, [storeCouplesGenders])

  useEffect(() => {
    if (storeDashEntities) {
      setStatsDashEntities(storeDashEntities.data)
    }
  }, [storeDashEntities])

  // Vilafranca
  useEffect(() => {
    if (storeVilafrancaAll) {
      setStatsVilafrancaAll(storeVilafrancaAll.data)
    }
  }, [storeVilafrancaAll])

  useEffect(() => {
    if (storeVilafrancaCouplesGenders) {
      setStatsVilafrancaCouplesGenders(storeVilafrancaCouplesGenders.data)
    }
  }, [storeVilafrancaCouplesGenders])

  useEffect(() => {
    if (storeVilafrancaSinglesGenders) {
      setStatsVilafrancaSinglesGenders(storeVilafrancaSinglesGenders.data)
    }
  }, [storeVilafrancaSinglesGenders])

  useEffect(() => {
    if (storeDashEntitiesVilafranca) {
      setStatsDashEntitiesVilafranca(storeDashEntitiesVilafranca.data)
    }
  }, [storeDashEntitiesVilafranca])

  // NotVilafranca
  useEffect(() => {
    if (storeNotVilafrancaAll) {
      setStatsNotVilafrancaAll(storeNotVilafrancaAll.data)
    }
  }, [storeNotVilafrancaAll])

  useEffect(() => {
    if (storeNotVilafrancaCouplesGenders) {
      setStatsNotVilafrancaCouplesGenders(storeNotVilafrancaCouplesGenders.data)
    }
  }, [storeNotVilafrancaCouplesGenders])

  useEffect(() => {
    if (storeNotVilafrancaSinglesGenders) {
      setStatsNotVilafrancaSinglesGenders(storeNotVilafrancaSinglesGenders.data)
    }
  }, [storeNotVilafrancaSinglesGenders])

  useEffect(() => {
    if (storeDashEntitiesNotVilafranca) {
      setStatsDashEntitiesNotVilafranca(storeDashEntitiesNotVilafranca.data)
    }
  }, [storeDashEntitiesNotVilafranca])

  return !statsGenders ? (
    <div className="flex items-center content-center justify-center w-full h-full">
      <p
        className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
        title="Carregant..."
      >
        Carregant!
        <img src={loadingIco}></img>
      </p>
    </div>
  ) : (
    <div className="grid w-full grid-cols-12 gap-4">
      <Dashboard
        isTotal={false}
        title="Individuals"
        className="bg-gray-100 xl:col-span-2"
        icon={iconSingle}
        fields={statsTowns}
      />

      <Dashboard
        isTotal={false}
        title="Parelles"
        className="bg-gray-100 xl:col-span-2"
        icon={iconCouple}
        fields={statsCouplesGenders}
      />

      <Dashboard
        isTotal={false}
        title="Total"
        className="bg-gray-100 xl:col-span-2"
        icon={iconDashboard}
        fields={statsGenders}
      />

      <Dashboard
        isTotal={true}
        title="Entitats"
        className="bg-gray-100 xl:col-span-2"
        icon={iconHome}
        fields={statsDashEntities}
      />

      <Dashboard
        isTotal={false}
        title="Individuals Vilafranca"
        className="bg-gray-200 xl:col-start-1 xl:col-end-3"
        icon={iconSingle}
        fields={statsVilafrancaSinglesGenders}
      />

      <Dashboard
        isTotal={false}
        title="Parelles Vilafranca"
        className="bg-gray-200 xl:col-span-2"
        icon={iconCouple}
        fields={statsVilafrancaCouplesGenders}
      />

      <Dashboard
        isTotal={false}
        title="Total Vilafranca"
        className="bg-gray-200 xl:col-span-2"
        icon={iconDashboard}
        fields={statsVilafrancaAll}
      />

      <Dashboard
        isTotal={true}
        title="Entitats Vilafranca"
        className="bg-gray-200 xl:col-span-2"
        icon={iconHome}
        fields={statsDashEntitiesVilafranca}
      />

      <Dashboard
        isTotal={false}
        title="Individuals Comarca"
        className="bg-gray-300 xl:col-start-1 xl:col-end-3"
        icon={iconSingle}
        fields={statsNotVilafrancaSinglesGenders}
      />

      <Dashboard
        isTotal={false}
        title="Parelles Comarca"
        className="bg-gray-300 xl:col-span-2"
        icon={iconCouple}
        fields={statsNotVilafrancaCouplesGenders}
      />

      <Dashboard
        isTotal={false}
        title="Total Comarca"
        className="bg-gray-300 xl:col-span-2"
        icon={iconDashboard}
        fields={statsNotVilafrancaAll}
      />

      <Dashboard
        isTotal={true}
        title="Entitats Comarca"
        className="bg-gray-300 xl:col-span-2"
        icon={iconHome}
        fields={statsDashEntitiesNotVilafranca}
      />
    </div>
  )
}
