// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import AllUsersTable from 'components/ui/Table/AllUsersTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import ColumnFilterSelect from 'components/ui/Table/ColumnFilterSelect'

export default function SinglesCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Si estamos en el apartad 'Comarca' no deberia salir en la select de poblaciones la población
  // 'Vilafranca del Penedès' por eso dependiendo de en qué página estemos, haremos una llamada a la API u
  // otra para recoger las poblaciones correspondientes que se tengan que mostrar en cada página:
  let urlTowns = ''
  if (window.location.href.includes('/comarca')) {
    urlTowns = 'entities/towns-no-vilafranca'
  } else {
    urlTowns = 'entities/towns'
  }
  const urlGroups = 'singles/groups'

  // Columnas:
  const columns = [
    {
      Header: 'Nom',
      accessor: 'Singles_Name',
      Filter: ColumnFilter,
      Type: 'name',
      Key: 'name'
    },
    {
      Header: 'Cognoms',
      accessor: 'Singles_Surnames',
      Filter: ColumnFilter,
      Type: 'surnames',
      Key: 'surnames'
    },
    {
      Header: 'Tlf.',
      accessor: 'Singles_Phone',
      Filter: ColumnFilter,
      Type: 'phone',
      Key: 'phone'
    },
    {
      Header: 'Correu',
      accessor: 'Singles_Email',
      Filter: ColumnFilter,
      Type: 'email',
      Key: 'email'
    },
    {
      Header: 'Grup',
      accessor: 'Singles_Grupo',
      Filter: ColumnFilterSelect,
      Url: urlGroups, // towns de proves, ara agafa del LocalStorage
      Type: 'group',
      Key: 'Grupo'
    },
    {
      Header: 'C.P.',
      accessor: 'Singles_PostalCode',
      Filter: ColumnFilter,
      Type: 'PostalCode',
      Key: 'PostalCode'
    },
    {
      Header: 'Població',
      accessor: 'Singles_Town',
      Filter: ColumnFilterSelect,
      Url: urlTowns, // towns de proves, ara agafa del LocalStorage
      Type: 'town',
      Key: 'town'
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Singles_Id: item.id,
          Singles_Name: item.Name,
          Singles_Surnames: item.Surnames,
          Singles_Town: item.Town,
          Singles_PostalCode: item.PostalCode,
          Singles_Studies: item.Studies,
          Singles_Phone: item.Phone,
          Singles_Mobile: item.MobilePhone,
          Singles_Email: item.Email,
          Singles_Photo: item.Photo,
          Singles_Grupo: item.Grupo,
          Singles_Address: item.Address,
          Singles_BirthDate: item.BirthDate,
          Singles_IsSingle: item.IsSingle
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <AllUsersTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

SinglesCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
