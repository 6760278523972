import AllCompGrid from 'components/Singles/AllCompGrid'
import loadingIco from 'components/ui/Logo/loading.gif'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

export default function AllUsersViewGrid() {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()

  // ############################################################

  // CRUD: GET
  useEffect(() => {
    const apiCallGet = {
      url: 'singles/all/users',
      method: 'GET',
      successMessage: null,
      failureMessage: 'Error de càrrega!'
    }
    setApiCallGet(apiCallGet)
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    const title = {
      name: 'TOTS els usuaris',

      button: 'print',
      export: true,
      csvData:
        getApiCallGet.data !== null &&
        getApiCallGet.data !== undefined &&
        getApiCallGet.data[0] !== null
          ? getApiCallGet.data
          : null
    }
    setTitle(title)
  }, [getApiCallGet.data])

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(
      Number(item.Singles_IsSingle) === 1
        ? `/singles/${item.Singles_Id}/all`
        : `/couples/${item.Singles_Id}/all`
    )
  }

  // ############################################################

  return getApiCallGet?.data ? (
    <>
      <div
        className={`w-full ${
          getApiCallGet.data.length > 0 ? 'block' : 'hidden'
        }`}
      >
        <div className="flex flex-col">
          <AllCompGrid items={getApiCallGet.data} handleClick={handleClick} />
        </div>
      </div>

      <div
        className={`w-full ${
          getApiCallGet.data.length > 0 ? 'hidden' : 'block'
        }`}
      >
        <div className="flex flex-col">
          <div
            className="px-4 py-3 text-red-900 bg-red-100 border-t-4 border-red-500 rounded-b shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="w-6 h-6 mr-4 text-red-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">Cap registre trobat.</p>
                <p className="text-sm">
                  Crea un nou individual prement el botó{' '}
                  <b>
                    <i>Crear Individual</i>
                  </b>
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco}></img>
        </p>
      </div>
    </div>
  )
}
