import { useContext, useEffect, useState } from 'react'
// import { useState } from 'react'
import { TitleContext } from 'contexts/TitleContext'
import { useLocation } from 'wouter'
import MailsCompGrid from 'components/Mails/MailsCompGrid'
import useFetch from 'hooks/useFetch'
import loadingIco from 'components/ui/Logo/loading.gif'
import { BsEnvelopePlus } from 'react-icons/bs'
import { toast } from 'react-toastify'

export default function MailsViewGrid(props) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [getApiCallGet, setApiCallGet] = useFetch()

  // CRUD: GET
  const apiCallGet = async () => {
    const apiCallGet = {
      url: 'mails',
      method: 'GET'
    }
    await setApiCallGet(apiCallGet)
  }

  useEffect(async () => {
    apiCallGet()
  }, [])

  useEffect(async () => {
    let timer

    if (getApiCallGet?.data && getApiCallGet?.data?.message) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })
      successMessage(getApiCallGet?.data?.message)

      timer = setTimeout(async () => {
        apiCallGet()
      }, 10000)
    }

    const mailsViewTitle = {
      name: 'Mails',
      buttons: [
        {
          name: (
            <span
              title="CREAR MAIL"
              className="flex flex-row items-center w-full"
            >
              <span className="pl-3 pr-1">
                <BsEnvelopePlus size={20} />
              </span>
              <span className="pl-1 pr-3">Crear Mail</span>
            </span>
          ),
          link: `/mails/new`
        }
      ],
      button: 'print',
      export: true,
      csvData: getApiCallGet?.data
    }
    setTitle(mailsViewTitle)

    return () => clearTimeout(timer)
  }, [getApiCallGet.data])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/mails/${item.Mails_Id}`)
  }

  return getApiCallGet?.data && !getApiCallGet?.data?.message ? (
    <>
      <div className={`w-full ${!getApiCallGet?.data?.length > 0 && 'hidden'}`}>
        <div className="flex flex-col">
          <MailsCompGrid
            items={getApiCallGet?.data}
            handleClick={handleClick}
          />
        </div>
      </div>

      <div className={`w-full ${getApiCallGet?.data?.length > 0 && 'hidden'}`}>
        <div className="flex flex-col">
          <div
            className="px-4 py-3 text-red-900 bg-red-100 border-t-4 border-red-500 rounded-b shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="w-6 h-6 mr-4 text-red-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">La bústia está buida</p>
                <p className="text-sm">No s&apos;han creat correus.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco}></img>
        </p>
      </div>
    </div>
  )
}
