// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import CouplesTable from 'components/ui/Table/CouplesTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import ColumnFilterSelect from 'components/ui/Table/ColumnFilterSelect'

export default function CouplesCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Si estamos en el apartad 'Comarca' no deberia salir en la select de poblaciones la población
  // 'Vilafranca del Penedès' por eso dependiendo de en qué página estemos, haremos una llamada a la API u
  // otra para recoger las poblaciones correspondientes que se tengan que mostrar en cada página:
  let urlTowns = ''
  if (window.location.href.includes('/comarca')) {
    urlTowns = 'entities/towns-no-vilafranca'
  } else {
    urlTowns = 'entities/towns'
  }
  const urlGroups = 'couples/groups'

  // Columnas:
  const columns = [
    {
      Header: 'Nom 1',
      accessor: 'Couples_Name1',
      Filter: ColumnFilter
    },
    {
      Header: 'Cognom 1',
      accessor: 'Couples_Surnames1',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom 2',
      accessor: 'Couples_Name2',
      Filter: ColumnFilter
    },
    {
      Header: 'Cognom 2',
      accessor: 'Couples_Surnames2',
      Filter: ColumnFilter
    },
    {
      Header: 'Grup',
      accessor: 'Couples_Grupo',
      Filter: ColumnFilter
    },
    {
      Header: 'C.P.',
      accessor: 'Couples_PostalCode',
      Filter: ColumnFilter
    },
    {
      Header: 'Població',
      accessor: 'Couples_Town',
      Filter: ColumnFilterSelect,
      Url: urlTowns, // de proves, pero el Component ja agafa de LocalStorage bones
      Type: 'town',
      Key: 'town'
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Couples_Id: item.Id,
          Couples_Grupo: item.Grupo,
          Couples_Name1: item.Name1,
          Couples_Surnames1: item.Surnames1,
          Couples_Name2: item.Name2,
          Couples_Surnames2: item.Surnames2,
          Couples_Town: item.Town,
          Couples_PostalCode: item.PostalCode
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <CouplesTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

CouplesCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
