import PropTypes from 'prop-types'
import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Sidebar from './Sidebar/Sidebar'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <>
      <section className="flex flex-col w-full mx-auto scroll-smooth print:m-0 print:p-0">
        {/* Barra navegación: */}
        <Navbar />

        <main className="flex flex-grow w-full print:m-0 print:p-0">
          <Sidebar />

          <div className="flex flex-col w-full overflow-y-hidden sm:ml-64 print:h-full print:p-0 print:m-0">
            <nav className="w-full py-3 font-semibold bg-green-400 border-l border-green-600 border-dashed bg-green print:hidden">
              <div className="w-full px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center justify-start w-full h-8">
                    <Title />
                  </div>
                </div>
              </div>
            </nav>

            <div className="flex-grow p-4 overflow-y-auto print:p-0 print:m-0">
              {/* <Title /> */}
              <div className="print:p-0 print:m-0">{children}</div>
            </div>
          </div>
        </main>

        {/* Pie de página (NO SE USA): */}
        {/* <Footer /> */}
      </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
