// Ruta app
import PropTypes from 'prop-types'
import { useLocation, Link } from 'wouter'

// Data
import { menuItems } from 'data/menuItems'

// Icons
import dashboardIcon from 'assets/icons/iconDashboard.svg'
// import entityIcon from 'assets/icons/iconEntity.svg'
import singleIcon from 'assets/icons/iconSingle.svg'
import coupleIcon from 'assets/icons/iconCouple.svg'
import adminIcon from 'assets/icons/headphones.png'
import homeIcon from 'assets/icons/home.svg'
import iconKey from 'assets/icons/iconKey.png'
import iconMail from 'assets/icons/mail.png'
import iconTicket from 'assets/icons/tag-icon.svg'
import iconLogout from 'assets/icons/logout.png'

// llogica
export default function Menu({ device, handleMobileMenu }) {
  const [location] = useLocation()

  if (device === 'mobile') {
    return (
      <>
        {menuItems.map((item) => (
          <Link
            key={item.slug}
            href={item.slug}
            onClick={() => handleMobileMenu()}
          >
            <a
              className={`${
                item.slug === location
                  ? 'bg-secondary text-white'
                  : 'text-white hover:bg-secondary hover:text-white'
              } block px-3 py-3 rounded-md text-base font-medium`}
            >
              {item.title}
            </a>
          </Link>
        ))}
      </>
    )
  } else {
    return (
      <>
        {menuItems.map((item) => (
          <Link href={item.slug} key={item.slug}>
            <a
              className={` flex px-4 py-2 mt-2 text-white text-sm font-semibold rounded-lg 
                  ${
                    item.slug === location
                      ? 'bg-gray-200 text-green-600'
                      : 'bg-transparent'
                  } 
                  ${
                    item.active &&
                    'hover:text-white focus:text-gray-900 hover:bg-green-400 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                  }
                  ${!item.active && 'cursor-default'}                        
                  ${item.subMenu ? 'ml-6' : 'ml-0'}
              `}
            >
              <img
                src={
                  item.title === 'Panell'
                    ? dashboardIcon
                    : item.title === 'Entitats'
                    ? homeIcon
                    : item.title === 'Vilafranca'
                    ? homeIcon
                    : item.title === 'Comarca'
                    ? homeIcon
                    : item.title === 'TOTS IND+PAR'
                    ? coupleIcon
                    : item.title === 'Individuals'
                    ? singleIcon
                    : item.title === 'Vilafranca '
                    ? singleIcon
                    : item.title === 'Comarca '
                    ? singleIcon
                    : item.title === 'Parelles'
                    ? coupleIcon
                    : item.title === 'Vilafranca  '
                    ? coupleIcon
                    : item.title === 'Comarca  '
                    ? coupleIcon
                    : item.title === 'Administrar'
                    ? adminIcon
                    : item.title === 'Usuaris'
                    ? iconKey
                    : item.title === 'Mail'
                    ? iconMail
                    : item.title === 'Etiquetes'
                    ? iconTicket
                    : item.title === 'Tancar'
                    ? iconLogout
                    : homeIcon
                }
                alt="customers"
                className="w-5 mr-2 h-5"
              />

              <span>{item.title.toUpperCase()}</span>

              {/* {item.count && (
                <span className="inline-flex ml-auto items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-primary rounded-full">
                  {item.count}
                </span> )  */}
            </a>
          </Link>
        ))}
      </>
    )
  }
}

Menu.propTypes = {
  device: PropTypes.string,
  handleMobileMenu: PropTypes.func
}
