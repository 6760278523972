import NotificationsIcon from 'components/icons/NotificationsIcon'
import PropTypes from 'prop-types'

export default function Notifications({ device }) {
  if (device === 'mobile') {
    return (
      <button className="ml-auto flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <span className="sr-only">View notifications</span>
        <NotificationsIcon className="h-6 w-6" />
      </button>
    )
  } else {
    return (
      <button className="p-1 pt-3 rounded-full text-white hover:text-white focus:outline-none mr-3">
        <span className="sr-only">View notifications</span>

        <span className="relative inline-block">
          <NotificationsIcon className="w-6 h-6 fill-current" />
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-secondary rounded-full">
            99
          </span>
        </span>
      </button>
    )
  }
}

Notifications.propTypes = {
  device: PropTypes.string
}
