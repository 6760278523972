// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useState, useContext } from 'react'

// FORMIK HOOK
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// CONTEXTS
import { ModalContext } from 'contexts/ModalContext'

// ASSETS
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'
import { BsEyeFill } from 'react-icons/bs'

const AdminFormDetail = ({ handleSubmit, handleDelete, fields, isNew }) => {
  // Contextos:
  // const { openModal } = useContext(ModalContext)

  // Estados:
  const [getShowPassword, setShowPassword] = useState(false)
  const [getShowNewPassword, setShowNewPassword] = useState(false)
  const [getShowRepeatNewPassword, setShowRepeatNewPassword] = useState(false)

  // Esquema validación formulario (POST):
  const validationSchemaPost = Yup.object().shape({
    Name: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Email: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .email('(* Formato erróneo)')
      .min(2, '(*)')
      .max(100, '(*)'),
    UserName: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Password: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
  })

  // Esquema validación formulario (PUT):
  const validationSchemaPut = Yup.object().shape({
    Name: Yup.string()
      .nullable()
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Email: Yup.string()
      .nullable()
      .required('(*)')
      .email('(* Formato erróneo)')
      .min(2, '(*)')
      .max(100, '(*)'),
    UserName: Yup.string()
      .nullable()
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    NewPassword: Yup.string()
      .nullable()
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
      .oneOf(
        [Yup.ref('RepeatNewPassword')],
        '(* Las contraseñas no coinciden)'
      ),
    RepeatNewPassword: Yup.string()
      .nullable()
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
      .oneOf([Yup.ref('NewPassword')], '(* Las contraseñas no coinciden)')
  })

  // Modal confirmación eliminación:
  const { openModal } = useContext(ModalContext)
  const handleDeleteModal = (id) => {
    const modalData = {
      id: id,
      handleDelete: handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        validationSchema={isNew ? validationSchemaPost : validationSchemaPut}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col w-full space-y-4">
            <div className="grid grid-cols-12 gap-6 p-4 m-0 border border-gray-300 rounded-sm shadow print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              {/* NOM */}
              <div title="NOM" className="col-span-6">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                    htmlFor="Name"
                  >
                    Nom
                  </label>

                  <ErrorMessage
                    name="Name"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Name && errors.Name
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                  name="Name"
                  type="text"
                />
              </div>

              {/* CORREU */}
              <div title="CORREU" className="col-span-6">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                    htmlFor="Email"
                  >
                    Correu
                  </label>

                  <ErrorMessage
                    name="Email"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Email && errors.Email
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                  name="Email"
                  type="email"
                />
              </div>

              {/* USUARI */}
              <div title="USUARI" className="col-span-6">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                    htmlFor="UserName"
                  >
                    Usuari
                  </label>

                  <ErrorMessage
                    name="UserName"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.UserName && errors.UserName
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                  name="UserName"
                  type="text"
                  autoComplete="new-password"
                />
              </div>

              {/* CONTRASENYA */}
              {isNew && (
                <div title="CONTRASENYA" className="col-span-12">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                      htmlFor="Password"
                    >
                      Contraseña
                    </label>

                    <ErrorMessage
                      name="Password"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <div className={`flex flex-row items-center`}>
                    <Field
                      className={`pl-2 rounded-sm flex-grow appearance-none px-3 p-2 border outline-none text-grey col-span-10
                          ${
                            touched.Password && errors.Password
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                          }`}
                      name="Password"
                      type={getShowPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                    />

                    <div className="w-24 h-full">
                      <button
                        className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 font-ms-semi border rounded-sm flex-row flex justify-center items-center
                            ${
                              getShowPassword
                                ? 'border-gray-400 bg-gray-300 text-gray-700'
                                : 'border-gray-700 bg-gray-600 text-white'
                            }`}
                        type="button"
                        alt="VEURE"
                        title="VEURE"
                        onClick={() =>
                          setShowPassword((prevValue) => !prevValue)
                        }
                      >
                        <BsEyeFill size={25} />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {!isNew && (
                <hr className="h-px col-span-12 my-3 bg-gray-200 border-0"></hr>
              )}

              {/* NOVA CONTRASENYA + REPETIR CONTRASENYA */}
              {!isNew && (
                <>
                  <div title="NOVA CONTRASENYA" className="col-span-12">
                    <div className="flex flex-row items-center mb-3">
                      <label
                        className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                        htmlFor="NewPassword"
                      >
                        Nova contrasenya
                      </label>

                      <ErrorMessage
                        name="NewPassword"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <div className={`flex flex-row items-center`}>
                      <Field
                        className={`pl-2 rounded-sm flex-grow appearance-none px-3 p-2 border outline-none text-grey col-span-10
                          ${
                            touched.NewPassword && errors.NewPassword
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                          }`}
                        name="NewPassword"
                        type={getShowNewPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                      />

                      <div className="w-24 h-full">
                        <button
                          className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 font-ms-semi border rounded-sm flex flex-row justify-center items-center
                            ${
                              getShowNewPassword
                                ? 'border-gray-300 bg-gray-200 text-gray-700'
                                : 'text-white bg-gray-600 border border-gray-700 hover:border-gray-300 hover:bg-gray-200 hover:text-gray-700'
                            }`}
                          type="button"
                          alt="VEURE"
                          title="VEURE"
                          onClick={() =>
                            setShowNewPassword((prevValue) => !prevValue)
                          }
                        >
                          <BsEyeFill size={25} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div title="REPETIR CONTRASENYA" className="col-span-12">
                    <div className="flex flex-row items-center mb-3">
                      <label
                        className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi"
                        htmlFor="RepeatNewPassword"
                      >
                        Repetir contrasenya
                      </label>

                      <ErrorMessage
                        name="RepeatNewPassword"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <div className={`flex flex-row items-center`}>
                      <Field
                        className={`pl-2 rounded-sm flex-grow appearance-none px-3 p-2 border outline-none text-grey
                        ${
                          touched.RepeatNewPassword && errors.RepeatNewPassword
                            ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                            : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                        }`}
                        name="RepeatNewPassword"
                        type={getShowRepeatNewPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                      />

                      <div className="w-24 h-full">
                        <button
                          className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 font-ms-semi border rounded-sm flex flex-row justify-center items-center
                          ${
                            getShowNewPassword
                              ? 'border-gray-300 bg-gray-200 text-gray-700'
                              : 'text-white bg-gray-600 border border-gray-700 hover:border-gray-300 hover:bg-gray-200 hover:text-gray-700'
                          }`}
                          type="button"
                          alt="VEURE"
                          title="VEURE"
                          onClick={() =>
                            setShowRepeatNewPassword((prevValue) => !prevValue)
                          }
                        >
                          <BsEyeFill size={25} />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                <button
                  alt="GUARDAR"
                  title="GUARDAR"
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm hover:border-green-300 hover:bg-green-200 hover:text-green-700 font-ms-semi"
                  type="submit"
                >
                  <AiOutlineSave size={25} className="mr-2" />
                  <p className="text-base font-semibold">Guardar</p>
                </button>
              </div>

              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    alt="ELIMINAR"
                    title="ELIMINAR"
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                    onClick={() => {
                      handleDeleteModal(values.Id)
                    }}
                    type="button"
                  >
                    <AiOutlineDelete size={25} className="mr-2" />
                    <p className="text-base font-semibold">Eliminar</p>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

AdminFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
}

export default AdminFormDetail
