// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import MailsFormDetail from 'forms/Mails/MailsFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading.gif'

export default function MailsViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallPostFile, setApiCallPostFile] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()
  const [getApiCallDeleteFile, setApiCallDeleteFile] = useFetch()
  const [getAllPartners, setAllPartners] = useFetch([])

  // Estados:
  const [getSendMailFields, setSendMailFields] = useState()
  const [getSendMailFiles, setSendMailFiles] = useState()
  const [exitOnSubmit, setExitOnSubmit] = useState(false)

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Mail`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/mails/grid`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Mail`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/mails/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    DateMail: null,
    Subject: '',
    Message: '',
    IndividualPartner: '',
    Partners: null,
    PartnersType: [],
    FileName: '',
    FilePath: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (params.id !== 'new') {
      const getApiCall = {
        url: `mails/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        Id: params.id
      }))

      setTitle(titleCreate)
    }
  }, [])

  // API Response: GET
  useEffect(() => {
    // Set initialValues
    if (getApiCallGet.data) {
      if (getApiCallGet.data.Partners) {
        getApiCallGet.data.Partners =
          '[{' + getApiCallGet.data.Partners.replaceAll(',', '},{') + '}]'
      }

      if (getApiCallGet.data.PartnersType) {
        getApiCallGet.data.PartnersType =
          '[' + getApiCallGet.data.PartnersType + ']'
        getApiCallGet.data.PartnersType = JSON.parse(
          getApiCallGet.data.PartnersType
        )
      }

      setFields({
        Id: getApiCallGet?.data?.id,
        DateMail: getApiCallGet?.data?.DateMail,
        Subject: getApiCallGet?.data?.Subject,
        Message: getApiCallGet?.data?.Message,
        IndividualPartner: getApiCallGet?.data?.IndividualPartner,
        Partners: getApiCallGet?.data?.Partners,
        PartnersType: getApiCallGet?.data?.PartnersType,
        FileName:
          getApiCallGet?.data?.FileName !== null &&
          getApiCallGet?.data?.FileName !== undefined &&
          getApiCallGet?.data?.FileName !== 'null'
            ? getApiCallGet?.data?.FileName?.split(',')
            : [],
        FilePath:
          getApiCallGet?.data?.FilePath !== null &&
          getApiCallGet?.data?.FilePath !== undefined &&
          getApiCallGet?.data?.FilePath !== 'null'
            ? getApiCallGet?.data?.FilePath?.split(',')
            : []
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values, e, isSendMail) => {
    const formData = new FormData()
    const files = e.current.files

    let url = `mails`
    let method = 'POST'

    if (values.Id !== 'new' && values.Id !== 0) {
      method = 'PUT'
      url += `/${values.Id}`
    }

    if (files) {
      if (files && files.length > 1) {
        Array.from(files).forEach((file) => {
          formData.append('document', file)
        })
      } else if (files) {
        formData.append('document', files[0])
      }
    }

    formData.append('id', values.Id)
    formData.append('DateMail', values.DateMail)
    formData.append('Subject', values.Subject)
    formData.append('Message', values.Message)
    formData.append('Partners', values.Partners)
    formData.append('PartnersType', values.PartnersType)
    formData.append('FileName', values.FileName)
    formData.append('FilePath', values.FilePath)
    formData.append('IndividualPartner', values.IndividualPartner)

    const apiCallPost = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      url: url,
      method: method,
      formData: formData
    }
    await setApiCallPost(apiCallPost)
  }

  // CRUD: POST / PUT
  const handleSubmitFile = async (values, e) => {
    const formData = new FormData()
    const files = e.currentTarget.files

    if (params.id === 'new') {
      formData.append('id', values.Id)
      formData.append('DateMail', values.DateMail)
      formData.append('Subject', values.Subject)
      formData.append('Message', values.Message)
      formData.append('Partners', values.Partners)
      formData.append('PartnersType', values.PartnersType)
      formData.append('FileName', values.FileName)
      formData.append('FilePath', values.FilePath)
      formData.append('IndividualPartner', values.IndividualPartner)
    }

    if (files) {
      if (files && files.length > 1) {
        Array.from(files).forEach((file) => {
          formData.append('document', file)
        })
      } else if (files) {
        formData.append('document', files[0])
      }
    }

    const apiCallPost = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      url: `mails/document/${fields.Id}`,
      method: 'POST',
      formData: formData
    }
    await setApiCallPostFile(apiCallPost)
  }

  // API Response: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)

        if (params.id === 'new') {
          setFields((prevFields) => ({
            ...prevFields,
            Id: getApiCallPost?.data?.results?.Id,
            DateMail: getApiCallPost?.data?.results?.DateMail,
            Subject: getApiCallPost?.data?.results?.Subject,
            Message: getApiCallPost?.data?.results?.Message,
            IndividualPartner: getApiCallPost?.data?.results?.IndividualPartner,
            Partners: getApiCallPost?.data?.results?.Partners,
            PartnersType: getApiCallPost?.data?.results?.PartnersType
              ? getApiCallPost?.data?.results?.PartnersType
              : ''
          }))

          params.id = getApiCallPost?.data?.results?.Id
        }

        exitOnSubmit && setLocation('/mails/grid')
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        exitOnSubmit && setLocation('/mails/grid')
      }
    }
  }, [getApiCallPost.loading])

  // API Response: POST / PUT
  useEffect(() => {
    if (getApiCallPostFile.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPostFile.message) {
        successMessage(getApiCallPostFile.message)

        if (params.id === 'new') {
          setFields((prevFields) => ({
            ...prevFields,
            Id: getApiCallPostFile?.data?.results?.Id,
            DateMail: getApiCallPostFile?.data?.results?.DateMail,
            Subject: getApiCallPostFile?.data?.results?.Subject,
            Message: getApiCallPostFile?.data?.results?.Message,
            IndividualPartner:
              getApiCallPostFile?.data?.results?.IndividualPartner,
            Partners: getApiCallPostFile?.data?.results?.Partners,
            PartnersType: getApiCallPostFile?.data?.results?.PartnersType
              ? getApiCallPostFile?.data?.results?.PartnersType
              : '',
            FileName:
              getApiCallPostFile?.data?.results?.FileName !== null &&
              getApiCallPostFile?.data?.results?.FileName !== undefined &&
              getApiCallPostFile?.data?.results?.FileName !== 'null'
                ? getApiCallPostFile?.data?.results?.FileName?.split(',')
                : [],
            FilePath:
              getApiCallPostFile?.data?.results?.FilePath !== null &&
              getApiCallPostFile?.data?.results?.FilePath !== undefined &&
              getApiCallPostFile?.data?.results?.FilePath !== 'null'
                ? getApiCallPostFile?.data?.results?.FilePath?.split(',')
                : []
          }))

          params.id = getApiCallPostFile?.data?.results?.Id
        } else {
          setFields((prevFields) => ({
            ...prevFields,
            FileName:
              getApiCallPostFile?.data?.results?.FileName !== null &&
              getApiCallPostFile?.data?.results?.FileName !== undefined &&
              getApiCallPostFile?.data?.results?.FileName !== 'null'
                ? getApiCallPostFile?.data?.results?.FileName?.split(',')
                : [],
            FilePath:
              getApiCallPostFile?.data?.results?.FilePath !== null &&
              getApiCallPostFile?.data?.results?.FilePath !== undefined &&
              getApiCallPostFile?.data?.results?.FilePath !== 'null'
                ? getApiCallPostFile?.data?.results?.FilePath?.split(',')
                : []
          }))
        }
      }

      if (getApiCallPostFile.error) {
        errorMessage(getApiCallPostFile.error)
      }
    }
  }, [getApiCallPostFile.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `mails/${params.id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // CRUD: DELETE
  const handleDeleteFile = async (fileName) => {
    const apiCallDelete = {
      url: `mails/document/${params.id}/${encodeURIComponent(fileName)}`,
      method: 'DELETE'
    }
    await setApiCallDeleteFile(apiCallDelete)
  }

  // API Response: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation('/mails/grid')
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation('/mails/grid')
      }
    }
  }, [getApiCallDelete.loading])

  // API Response: DELETE
  useEffect(() => {
    if (getApiCallDeleteFile.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDeleteFile.message) {
        successMessage(getApiCallDeleteFile.message)

        setFields((prevFields) => ({
          ...prevFields,
          FileName:
            getApiCallDeleteFile?.data?.results?.FileName !== null &&
            getApiCallDeleteFile?.data?.results?.FileName !== undefined &&
            getApiCallDeleteFile?.data?.results?.FileName !== 'null'
              ? getApiCallDeleteFile?.data?.results?.FileName?.split(',')
              : [],
          FilePath:
            getApiCallDeleteFile?.data?.results?.FilePath !== null &&
            getApiCallDeleteFile?.data?.results?.FilePath !== undefined &&
            getApiCallDeleteFile?.data?.results?.FilePath !== 'null'
              ? getApiCallDeleteFile?.data?.results?.FilePath?.split(',')
              : []
        }))
      }

      if (getApiCallDeleteFile.error) {
        errorMessage(getApiCallDeleteFile.error)
      }
    }
  }, [getApiCallDeleteFile.loading])

  // ############################################################

  const handleSendEmail = async (values, e) => {
    if (values.PartnersType && values.PartnersType.length > 0) {
      setSendMailFields(values)
      setSendMailFiles(e.current.files)

      const apiCallPost = {
        url: `mails/emails/${encodeURIComponent(
          values.PartnersType.toString()
        )}`,
        method: 'GET'
      }
      await setAllPartners(apiCallPost)
    } else {
      if (
        values.IndividualPartner !== null &&
        values.IndividualPartner !== ''
      ) {
        handleSendIndividualEmail(values, e)
      } else {
        const errorMessage = (error) =>
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER
          })
        errorMessage('Seleccioni destinataris si us plau!')
      }
    }
  }

  const handleSendIndividualEmail = async (values, e) => {
    const formData = new FormData()
    const files = e.current.files

    if (files) {
      if (files && files.length > 1) {
        Array.from(files).forEach((file) => {
          formData.append('document', file)
        })
      } else if (files) {
        formData.append('document', files[0])
      }
    }

    formData.append('id', values.Id)
    formData.append('DateMail', values.DateMail)
    formData.append('Subject', values.Subject)
    formData.append('Message', values.Message)
    formData.append(
      'Partners',
      JSON.stringify([{ Mail: values.IndividualPartner }])
    )
    formData.append('PartnersType', values.PartnersType)
    formData.append('IndividualPartner', values.IndividualPartner)
    formData.append('FileName', values.FileName)
    formData.append('FilePath', values.FilePath)

    const apiCallSendMails = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      url: `mails/sendAll`,
      method: 'POST',
      formData: formData
    }
    await setApiCallPost(apiCallSendMails)
  }

  useEffect(async () => {
    if (getAllPartners.data) {
      const formData = new FormData()
      const files = getSendMailFiles
      const values = getSendMailFields

      const distinctArray = Array.from(
        new Set(getAllPartners.data.map((obj) => obj.Mail))
      ).map((mail) => getAllPartners.data.find((obj) => obj.Mail === mail))

      if (
        values.IndividualPartner !== null &&
        values.IndividualPartner !== ''
      ) {
        distinctArray.push({ Mail: values.IndividualPartner })
      }

      if (files) {
        if (files && files.length > 1) {
          Array.from(files).forEach((file) => {
            formData.append('document', file)
          })
        } else if (files) {
          formData.append('document', files[0])
        }
      }

      formData.append('id', values.Id)
      formData.append('DateMail', values.DateMail)
      formData.append('Subject', values.Subject)
      formData.append('Message', values.Message)
      formData.append('Partners', JSON.stringify(distinctArray))
      formData.append('PartnersType', values.PartnersType)
      formData.append('IndividualPartner', values.IndividualPartner)
      formData.append('FileName', values.FileName)
      formData.append('FilePath', values.FilePath)

      const apiCallSendMails = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        url: `mails/sendAll`,
        method: 'POST',
        formData: formData
      }
      await setApiCallPost(apiCallSendMails)
    }
  }, [getAllPartners.data])

  // ############################################################

  return fields.Id !== 0 ? (
    <MailsFormDetail
      handleSubmit={handleSubmit}
      handleSubmitFile={handleSubmitFile}
      handleDelete={handleDelete}
      handleDeleteFile={handleDeleteFile}
      handleSendEmail={handleSendEmail}
      fields={fields}
      setFields={setFields}
      setExitOnSubmit={setExitOnSubmit}
    />
  ) : (
    <div className="flex items-center content-center justify-center w-full h-full">
      <p
        className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
        title="Carregant..."
      >
        Carregant!
        <img src={loadingIco}></img>
      </p>
    </div>
  )
}

MailsViewDetail.propTypes = {
  params: PropTypes.any
}
