// PROPS
import PropTypes from 'prop-types'
// UI
import MailsTable from 'components/ui/Table/MailsTable'
// import Table from 'components/ui/Table/Table'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

// STATE
import { useEffect, useState } from 'react'

// COMPONENT
export default function MailsCompGrid({ items, handleClick }) {
  // grabar en STATE els valors
  const [stateTableData, setTableData] = useState([])

  const columns = [
    {
      Header: 'Id',
      accessor: 'Mails_Id',
      Filter: ColumnFilter
    },
    {
      Header: 'Data Mail',
      accessor: 'Mails_DateMail',
      Filter: ColumnFilter
    },
    {
      Header: 'Assumpte',
      accessor: 'Mails_Subject',
      Filter: ColumnFilter
    },
    {
      Header: 'Missatge',
      accessor: 'Mails_Message',
      Filter: ColumnFilter
    },
    {
      Header: 'Destinataris',
      accessor: 'Mails_PartnersType',
      Filter: ColumnFilter
    },
    {
      Header: 'Enviat',
      accessor: 'Mails_IsSent',
      Filter: ColumnFilter
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = []
      items.forEach((item) => {
        itemsData.push({
          Mails_Id: item.id,
          Mails_DateMail: item.DateMail !== 'null' ? item.DateMail : '',
          Mails_Subject: item.Subject,
          Mails_Message: item.Message,
          Mails_PartnersType:
            item.PartnersType !== ''
              ? getPartnersTypeName(JSON.parse('[' + item.PartnersType + ']'))
              : '',
          Mails_IsSent: item.IsSent
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  const getPartnersTypeName = (partners) => {
    let partnerToReturn = ''

    for (let i = 0; i < partners.length; i++) {
      switch (partners[i]) {
        case 1:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Entitats (Totes)'
          } else {
            partnerToReturn += 'Entitats (Totes) - '
          }
          break

        case 2:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Entitats (Vilafranca)'
          } else {
            partnerToReturn += 'Entitats (Vilafranca) - '
          }
          break

        case 3:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Entitats (Comarca)'
          } else {
            partnerToReturn += 'Entitats (Comarca) - '
          }
          break

        case 4:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Individuals (Tots)'
          } else {
            partnerToReturn += 'Individuals (Tots) - '
          }
          break

        case 5:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Individuals (Vilafranca)'
          } else {
            partnerToReturn += 'Individuals (Vilafranca) - '
          }
          break

        case 6:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Individuals (Comarca)'
          } else {
            partnerToReturn += 'Individuals (Comarca) - '
          }
          break

        case 7:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Parelles (Totes)'
          } else {
            partnerToReturn += 'Parelles (Totes) - '
          }
          break

        case 8:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Parelles (Vilafranca)'
          } else {
            partnerToReturn += 'Parelles (Vilafranca) - '
          }
          break

        case 9:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Parelles (Comarca)'
          } else {
            partnerToReturn += 'Parelles (Comarca) - '
          }
          break

        case 10:
          if (i + 1 === partners.length) {
            partnerToReturn += 'Socis (Tots)'
          } else {
            partnerToReturn += 'Socis (Tots) - '
          }
          break
        default:
          break
      }
    }

    // console.log(partnerToReturn)
    return partnerToReturn
  }

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {stateTableData.length > 0 && (
        <MailsTable
          data={stateTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

MailsCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
