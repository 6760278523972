// PropTypes & HOOKS
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'

// CONTEXTS
import { ModalContext } from 'contexts/ModalContext'
import { useContext } from 'react'

// ASSETS
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai'

const EntitiesFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
  const entityTypeOptions = [
    { label: 'AULA', value: 'AULA', icon: 'Alt-11' },
    { label: 'ENTITAT', value: 'ENTITAT', icon: 'Alt-12' }
  ]

  // Modal confirmación eliminación:
  const { openModal } = useContext(ModalContext)
  const handleDeleteModal = (id) => {
    const modalData = {
      id: id,
      handleDelete: handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col w-full space-y-4">
            <div className="grid grid-cols-12 grid-rows-6 gap-6 p-4 m-0 border border-gray-300 shadow rounded-sm-sm print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              {/* NOM CORPORATIU */}
              <div title="NOM CORPORATIU" className="col-span-6 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="NameEntity"
                  >
                    Nom Corporatiu
                  </label>

                  <ErrorMessage
                    name="NameEntity"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.NameEntity && errors.NameEntity
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="NameEntity"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* OBSERVACIONS */}
              <div
                title="OBSERVACIONS"
                className="flex flex-col col-span-6 row-span-3"
              >
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Obervation"
                  >
                    Observacions
                  </label>

                  <ErrorMessage
                    name="Obervation"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`flex-grow pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey align-top 
                ${
                  touched.Observations && errors.Observations
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  as="textarea"
                  name="Observations"
                  value={values.Observations ?? ''}
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* RAÓ SOCIAL */}
              <div title="RAÓ SOCIAL" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="SecondName"
                  >
                    Raó Social
                  </label>

                  <ErrorMessage
                    name="SecondName"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.SecondName && errors.SecondName
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="SecondName"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* TIPUS */}
              <div title="TIPUS" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="EntityType"
                  >
                    Tipus
                  </label>

                  <ErrorMessage
                    name="EntityType"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Select
                  className="w-full rounded-sm text-grey-dark"
                  name="EntityType"
                  onChange={(e) => setFieldValue('EntityType', e.value)}
                  placeholder="Seleccionar..."
                  options={entityTypeOptions}
                  value={entityTypeOptions.find(
                    (option) => option.value === values.EntityType
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '2px 0px 2px 0px',
                      border: state.isFocused
                        ? '1px solid #9ca3af'
                        : '1px solid #d1d5db',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      borderRadius: '0.125rem',
                      cursor: 'pointer'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: '#333333',
                      backgroundColor: state.isFocused && '#d1d5db',
                      '&:hover': {
                        backgroundColor: '#f3f4f6'
                      },
                      cursor: 'pointer'
                    })
                  }}
                />
              </div>

              {/* MÒBIL */}
              <div title="MÒBIL" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Phone"
                  >
                    Mòbil
                  </label>

                  <ErrorMessage
                    name="Phone"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.Phone && errors.Phone
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="Phone"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* TELÈFON FIXE */}
              <div title="TELÈFON FIXE" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Phone2"
                  >
                    Tlf.
                  </label>

                  <ErrorMessage
                    name="Phone2"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.Phone2 && errors.Phone2
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="Phone2"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* POBLACIÓ */}
              <div title="POBLACIÓ" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Town"
                  >
                    Població
                  </label>

                  <ErrorMessage
                    name="Town"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.Town && errors.Town
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="Town"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* CODI POSTAL */}
              <div title="CODI POSTAL" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="PostalCode"
                  >
                    C.P.
                  </label>

                  <ErrorMessage
                    name="PostalCode"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.PostalCode && errors.PostalCode
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="PostalCode"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* CORREU */}
              <div title="CORREU" className="col-span-4 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Email"
                  >
                    Correu
                  </label>

                  <ErrorMessage
                    name="Email"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.Email && errors.Email
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="Email"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* ADREÇA */}
              <div title="ADREÇA" className="col-span-12 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4"
                    htmlFor="Address"
                  >
                    Adreça
                  </label>

                  <ErrorMessage
                    name="Address"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                ${
                  touched.Address && errors.Address
                    ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                    : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                  name="Address"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
            </div>

            <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                <button
                  alt="GUARDAR"
                  title="GUARDAR"
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-green-200 hover:text-green-700 font-ms-semi"
                  type="submit"
                >
                  <AiOutlineSave size={25} className="mr-2" />
                  <p className="text-base font-semibold">Guardar</p>
                </button>
              </div>

              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    alt="ELIMINAR"
                    title="ELIMINAR"
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                    onClick={() => {
                      handleDeleteModal(values.Id)
                    }}
                    type="button"
                  >
                    <AiOutlineDelete size={25} className="mr-2" />
                    <p className="text-base font-semibold">Eliminar</p>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

EntitiesFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
}

export default EntitiesFormDetail
