export const menuItems = [
  {
    title: 'Panell',
    slug: '/',
    active: true,
    subMenu: false
  },
  {
    title: 'Entitats',
    slug: '/entities/grid',
    active: true,
    count: 24,
    subMenu: false
  },
  {
    title: 'Vilafranca',
    slug: '/entities/grid/vilafranca',
    active: true,
    count: 24,
    subMenu: true
  },
  {
    title: 'Comarca',
    slug: '/entities/grid/comarca',
    active: true,
    count: 24,
    subMenu: true
  },
  {
    title: 'TOTS IND+PAR',
    slug: '/all/grid',
    active: true,
    subMenu: false
  },
  {
    title: 'Individuals',
    slug: '/singles/grid',
    active: true,
    subMenu: false
  },
  {
    title: 'Vilafranca ',
    slug: '/singles/grid/vilafranca',
    active: true,
    subMenu: true
  },
  {
    title: 'Comarca ',
    slug: '/singles/grid/comarca',
    active: true,
    subMenu: true
  },
  {
    title: 'Parelles',
    slug: '/couples/grid',
    active: true,
    subMenu: false
  },
  {
    title: 'Vilafranca  ',
    slug: '/couples/grid/vilafranca',
    active: true,
    subMenu: true
  },
  {
    title: 'Comarca  ',
    slug: '/couples/grid/comarca',
    active: true,
    subMenu: true
  },
  {
    title: 'Administrar',
    slug: '',
    active: false,
    subMenu: false
  },
  {
    title: 'Usuaris',
    slug: '/admin/grid',
    active: true,
    subMenu: true
  },
  {
    title: 'Mail',
    slug: '/mails/grid',
    active: true,
    subMenu: true
  },
  {
    title: 'Etiquetes',
    slug: '/tags',
    active: true,
    subMenu: true
  },
  {
    title: 'Tancar',
    slug: '/logout',
    active: true,
    subMenu: false
  }
]
