import PropTypes from 'prop-types'

export default function Dashboard({ isTotal, title, className, icon, fields }) {
  let suma = 0

  if (fields) {
    suma = fields.Homes + fields.Dones + fields.NC
  }

  return (
    <>
      <div
        className={`relative flex flex-col col-span-12 p-4 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 ${className} h-80 print:hidden`}
      >
        <img src={icon} className="mb-3 w-7 h-7" alt="icon" />

        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
          {title}
        </h5>

        <div className="flex flex-col justify-center w-full mt-auto">
          {isTotal ? (
            <>
              <hr className="w-full h-px my-3 bg-green-600 border-0"></hr>

              <p className="w-full text-gray-600 sm:text-base">
                Total:
                <span className="text-green-600 sm:text-base">
                  {''} &nbsp;&nbsp;{fields?.TOTAL ?? 'n.c.'}
                </span>{' '}
                Entitats.
              </p>
            </>
          ) : (
            <>
              <p className="w-full mb-3 font-normal text-gray-500">
                {fields?.Homes ?? 'n.c.'} - Homes
              </p>

              <p className="w-full mb-3 font-normal text-gray-500">
                {fields?.Dones ?? 'n.c.'} - Dones
              </p>

              <p className="w-full font-normal text-gray-500">
                {fields?.NC ?? 'n.c.'} - N.C.
              </p>

              <hr className="w-full h-px my-3 bg-green-600 border-0"></hr>

              <p className="w-full font-normal text-gray-500">
                Total:
                <span className="text-green-600 sm:text-base">
                  {''} &nbsp;&nbsp;{suma ? suma.toLocaleString() : ''}
                </span>{' '}
                persones.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = {
  isTotal: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  fields: PropTypes.array
}
