// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import CouplesFormDetail from 'forms/Couples/CouplesFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading.gif'

export default function CouplesViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params.id === 'new'
  const type = params.type

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Parella`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="w-full flex flex-row items-center">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link:
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Parella`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="w-full flex flex-row items-center">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link:
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    Town: '',
    PostalCode: '',
    Address: '',
    Grupo: '',
    Name1: '',
    Surnames1: '',
    BirthDate1: '',
    Phone1: '',
    MobilePhone1: '',
    Studies1: '',
    Email1: '',
    Photo1: '',
    DNI1: '',
    Num_SS_1: '',
    Gender1: '',
    Name2: '',
    Surnames2: '',
    BirthDate2: '',
    Phone2: '',
    MobilePhone2: '',
    Studies2: '',
    Email2: '',
    Photo2: '',
    DNI2: '',
    Num_SS_2: '',
    Gender2: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `couples/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Town: getApiCallGet.data.Town,
        PostalCode: getApiCallGet.data.PostalCode,
        Address: getApiCallGet.data.Address,
        Grupo: getApiCallGet.data.Grupo,
        Name1: getApiCallGet.data.Name1,
        Surnames1: getApiCallGet.data.Surnames1,
        BirthDate1: '1999/01/01',
        Phone1: getApiCallGet.data.Phone1,
        MobilePhone1: getApiCallGet.data.MobilePhone1,
        Studies1: getApiCallGet.data.Studies1,
        Email1: getApiCallGet.data.Email1,
        Photo1: getApiCallGet.data.Photo1,
        DNI1: getApiCallGet.data.DNI1,
        Num_SS_1: getApiCallGet.data.Num_SS_1,
        Gender1: getApiCallGet.data.Gender1,
        Name2: getApiCallGet.data.Name2,
        Surnames2: getApiCallGet.data.Surnames2,
        BirthDate2: '1999/01/01',
        Phone2: getApiCallGet.data.Phone2,
        MobilePhone2: getApiCallGet.data.MobilePhone2,
        Studies2: getApiCallGet.data.Studies2,
        Email2: getApiCallGet.data.Email2,
        Photo2: getApiCallGet.data.Photo2,
        DNI2: getApiCallGet.data.DNI2,
        Num_SS_2: getApiCallGet.data.Num_SS_2,
        Gender2: getApiCallGet.data.Gender2
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `couples`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `couples/${params.id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/couples/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (isNew && fields.Id === 0) || (!isNew && fields.Id !== 0) ? (
    <CouplesFormDetail
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div className="h-full w-full flex justify-center items-center content-center">
      <p
        className="h-full text-green-700 font-extrabold flex justify-center flex-col-reverse items-center"
        title="Carregant..."
      >
        Carregant!
        <img src={loadingIco}></img>
      </p>
    </div>
  )
}

CouplesViewDetail.propTypes = {
  params: PropTypes.any
}
