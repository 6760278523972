import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const TitleContext = createContext(null)

export const TitleContextProvider = ({ children }) => {
  const [title, setTitle] = useState({})

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}
TitleContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
