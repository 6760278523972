import PropTypes from 'prop-types'

import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import logoExcel from 'assets/img/logoExcel.png'
import logoPDF from 'assets/img/logoPDF.png'
import logoAula from 'assets/img/logo-white.png'
import logoMail from 'assets/icons/mail.png'
import flecha from 'assets/img/imageInvert.png'

import ReactExport from 'react-export-excel'
const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport

export default function MailsTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  let rowsEmpty = Boolean
  if (datos) {
    // console.log('datos: ', datos)
    rowsEmpty = false
  } else {
    // console.log('no hay datos')
    rowsEmpty = true
  }

  // Función para exportar todos los Mails en un PDF:
  function generateMailsPDF() {
    // jsPDF es una libreria de React que nos permite descargar, exportar o crear PDFs automáticamente:
    const doc = new JsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4'
    })

    doc.setFontSize(8)

    // Creamos un array con los valores que tendrá el 'header' de la tabla:
    const headers = ['Id', 'Data', 'Assumpte', 'Missatge', 'Socis']
    // Creamos un array para las filas de la tabla:
    const rows = []

    // Por cada usuario que nos ha llegado desde la base de datos crearemos una fila para la tabla:
    datos.forEach((mail) => {
      const temp = [
        mail.Mails_Id,
        mail.Mails_DateMail,
        mail.Mails_Subject,
        mail.Mails_Message,
        mail.Mails_PartnersType
      ]
      rows.push(temp)
    })

    // Grácias al plugin 'AutoTable' de jsPDF podremos generar una tabla automáticamente, enviando el header, las filas y añadiendo
    // estilos que queramos, toda la información de este plugin está documentada en https://www.npmjs.com/package/jspdf-autotable/v/3.2.4?activeTab=readme
    // Nosotros tenemos la versión 3.2.4:
    doc.autoTable(headers, rows, {
      startY: 87,
      headStyles: { fillColor: [19, 150, 105], fontSize: 8 },
      alternateRowStyles: { fillColor: [236, 253, 245] },
      margin: { top: 87, bottom: 50 },
      bodyStyles: { fontSize: 8 }
    })

    // Contamos todas las páginas para crear la paginación en el PDF:
    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 0; i < pageCount; i++) {
      doc.setPage(i)

      const pageCurrent = doc.internal.getCurrentPageInfo().pageNumber
      doc.setFontSize(16)
      doc.setFont('Helvetica', 'bold')

      // Añadimos el icono del título "Mails":
      doc.addImage(
        logoMail,
        'png',
        doc.internal.pageSize.width / 2 - doc.internal.pageSize.width / 6 - 22,
        35.3,
        15,
        15
      )

      // Añadimos el título "Mails":
      doc.text(
        'Mails',
        doc.internal.pageSize.width / 2 - doc.internal.pageSize.width / 6 + 20,
        50,
        { align: 'center' }
      )

      // Añadimos el logo en la esquina superior izquierda de todas las páginas:
      doc.addImage(
        logoAula,
        'png',
        doc.internal.pageSize.width / 2 - 25,
        20,
        50,
        50
      )

      // Añadimos el título "AULA Vilafranca":
      doc.text(
        'AULA Vilafranca',
        doc.internal.pageSize.width / 2 + doc.internal.pageSize.width / 5,
        52.1,
        { align: 'center' }
      )

      doc.setFontSize(8)
      doc.setFont(undefined, 'normal')

      // Añadimos el número de página en el centro inferior de todas las páginas:
      doc.text(
        pageCurrent + ' / ' + pageCount,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.height - 30,
        { align: 'center' }
      )
    }

    // Cogemos el dia y la hora actuales para añadirlo al nombre del PDF:
    const fechaPDF = new Date()

    doc.save(
      'Mails' +
        '_' +
        fechaPDF.getDate() +
        '-' +
        (fechaPDF.getMonth() + 1) +
        '-' +
        fechaPDF.getFullYear() +
        '_' +
        fechaPDF.getHours() +
        '-' +
        fechaPDF.getMinutes() +
        '-' +
        fechaPDF.getSeconds() +
        '.pdf'
    )
  }

  // Cogemos el dia y la hora actuales para añadirlo al nombre del archivo Excel:
  const fechaExcel = new Date()
  const excelName =
    'Mails' +
    '_' +
    fechaExcel.getDate() +
    '-' +
    (fechaExcel.getMonth() + 1) +
    '-' +
    fechaExcel.getFullYear() +
    '_' +
    fechaExcel.getHours() +
    '-' +
    fechaExcel.getMinutes() +
    '-' +
    fechaExcel.getSeconds() +
    '.pdf'

  return (
    <div className="grid grid-cols-12 pr-2 text-center bg-white border-t-2 border-green-600 print:hidden">
      <div className="flex items-center justify-start col-span-4">
        {/* Botón para exportar o descargar los mails en un documento Excel: */}
        <ExcelFile
          element={
            <button
              disabled={rowsEmpty}
              hidden={rowsEmpty}
              className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
              style={{ width: '85px', marginRight: '5px', height: '85px' }}
            >
              <img src={logoExcel} alt="icon" />
            </button>
          }
          filename={excelName}
        >
          <ExcelSheet data={datos} name="Mails">
            <ExcelColumn label="Id" value="Mails_Id" />
            <ExcelColumn label="Data Mail" value="Mails_DateMail" />
            <ExcelColumn label="Assumpte" value="Mails_Subject" />
            <ExcelColumn label="Missatge" value="Mails_Message" />
            <ExcelColumn label="Socis" value="Mails_PartnersType" />
          </ExcelSheet>
        </ExcelFile>

        {/* Botón para exportar o descargar los mails en un documento PDF: */}
        <button
          onClick={generateMailsPDF}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '60px', marginRight: '7px', height: '60px' }}
        >
          <img src={logoPDF} className="hover:scale-125" alt="icon" />
        </button>
      </div>

      {/* Paginación: */}
      {pageOptions.length > 1 && (
        <div className="flex items-center col-span-4 place-self-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>

          <span>
            Pàgina <strong>{(pageIndex + 1).toLocaleString()}</strong> de{' '}
            <strong>
              {pageOptions.length.toLocaleString()} {' ('}
              {datos.length.toLocaleString()} registres{')'}
            </strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>
      )}

      {/* Cantidad de registros mostrados por página: */}
      {pageOptions.length > 1 && (
        <select
          className="self-center float-right col-span-4 p-1 font-semibold border-2 border-green-700 rounded outline-none bg-green-50 justify-self-end"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} registres
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

MailsTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
}
