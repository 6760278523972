// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import EntitiesTable from 'components/ui/Table/EntitiesTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'
import ColumnFilterSelect from 'components/ui/Table/ColumnFilterSelect'

export default function EntitiesCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Si estamos en el apartad 'Comarca' no deberia salir en la select de poblaciones la población
  // 'Vilafranca del Penedès' por eso dependiendo de en qué página estemos, haremos una llamada a la API u
  // otra para recoger las poblaciones correspondientes que se tengan que mostrar en cada página:
  let urlTowns = ''
  if (window.location.href.includes('/comarca')) {
    urlTowns = 'entities/towns-no-vilafranca'
  } else {
    urlTowns = 'entities/towns'
  }
  const urlEntityType = 'entities/entitytype'

  const columns = [
    {
      Header: 'Entitat',
      accessor: 'Entities_Name',
      Filter: ColumnFilter,
      Type: 'nameEntity',
      Key: 'nameEntity'
    },
    {
      Header: 'Raó Social',
      accessor: 'Entities_SecondName',
      Filter: ColumnFilter,
      Type: 'secondName',
      Key: 'secondName'
    },
    {
      Header: 'Tlf.',
      accessor: 'Entities_Phone',
      Filter: ColumnFilter,
      Type: 'phone',
      Key: 'phone'
    },
    {
      Header: 'Correu',
      accessor: 'Entities_Email',
      Filter: ColumnFilter,
      Type: 'email',
      Key: 'email'
    },
    {
      Header: 'Tipus',
      accessor: 'Entities_Type',
      Filter: ColumnFilterSelect,
      Url: urlEntityType,
      Type: 'EntityType',
      Key: 'option'
    },
    {
      Header: 'C.P.',
      accessor: 'Entities_PostalCode',
      Filter: ColumnFilter,
      Type: 'PostalCode',
      Key: 'PostalCode'
    },
    {
      Header: 'Població',
      accessor: 'Entities_Town',
      Filter: ColumnFilterSelect,
      Url: urlTowns,
      Type: 'town',
      Key: 'option'
    }
  ]

  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Entities_Id: item.Id,
          Entities_Type: item.EntityType,
          Entities_Name: item.NameEntity,
          Entities_SecondName: item.SecondName,
          Entities_Town: item.Town,
          Entities_Phone: item.Phone,
          Entities_Phone2: item.Phone2,
          Entities_Email: item.Email,
          Entities_PostalCode: item.PostalCode
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <EntitiesTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

EntitiesCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
