import PropTypes from 'prop-types'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'

export default function ColumnFilterSelect({ column }) {
  const [sortedOptions, setSortedOptions] = useState()
  const { filterValue, setFilter } = column
  // const [options, getTowns] = useState(localTowns)
  const [townOptions, getTowns] = useFetch()
  const apiUrl = column.Url
  const requestType = column.Type

  // console.log(filterValue + " Sebas")

  useEffect(() => {
    const getTownsAPIParams = {
      url: apiUrl,
      method: 'GET',
      messageKo: 'Error al rebre ' + requestType + '!'
    }
    getTowns(getTownsAPIParams)
  }, [])

  useEffect(() => {
    if (townOptions.data) {
      townOptions.data.sort(function (a, b) {
        const x = a
        const y = b
        if (x.option < y.option) {
          return -1
        }

        if (x.option > y.option) {
          return 1
        }

        return 0
      })
    }

    setSortedOptions(townOptions.data)
  }, [townOptions.data])

  function noVilafrancaFunc(e) {
    if (e.target.value === 'noVilafranca') {
      console.log('novilafranca')
    }

    setFilter(e.target.value)
  }

  return (
    <div className="print:hidden">
      <select
        className={`border border-gray-200 text-sm focus:outline-none font-lato rounded-sm py-1 px-2  w-full mt-2 text-black`}
        value={filterValue || ''}
        onChange={(e) => noVilafrancaFunc(e)}
        onClick={(e) => e.stopPropagation()}
        id={requestType}
        disabled={
          window.location.href.includes('/vilafranca') && requestType === 'town'
            ? true
            : null
        }
      >
        <option value="">
          {window.location.href.includes('/vilafranca') &&
          requestType === 'town'
            ? 'Vilafranca del Penedès'
            : 'Totes (no filtrar)'}
        </option>

        {/* {console.log(requestType)} */}
        {/* {console.log('sortedOptions: ', sortedOptions)} */}
        {sortedOptions
          ? sortedOptions.map((option) => (
              <option value={option.option} key={option.option}>
                {option.option}
              </option>
            ))
          : ''}
      </select>
    </div>
  )
}

ColumnFilterSelect.propTypes = {
  column: PropTypes.object.isRequired
}
