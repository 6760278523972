// PropTypes & React HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import MailsTableBar from './MailsTableBar'
import { BsSendCheckFill, BsSendXFill } from 'react-icons/bs'

export default function MailsTable({ data, columns, handleClick }) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state

  return (
    <div className="flex flex-col">
      <div className="inline-block min-w-full align-middle">
        {/* Tabla: */}
        <table
          className="min-w-full border-2 border-green-600 table-auto"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-green-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-green-600 divide-dashed"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className={`text-white py-2 font-ms-semi 
                    ${
                      column.render('Header') !== 'Enviat'
                        ? 'hover:bg-green-500'
                        : 'cursor-default'
                    }`}
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div
                      className={`text-sm ${
                        column.render('Header') === 'Enviat'
                          ? 'text-center cursor-default'
                          : 'text-left ml-4'
                      }`}
                    >
                      {column.render('Header')}

                      {column.isSorted &&
                      column.render('Header') !== 'Enviat' ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-green-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-green-200
                  ${index % 2 === 0 && 'bg-green-50'} `}
                >
                  <td className={`text-sm pl-4 py-4 w-1/12 font-bold`}>
                    {page[index].original.Mails_Id}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-1/12`}>
                    {page[index].original.Mails_DateMail}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-3/12`}>
                    {page[index].original.Mails_Subject}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-3/12`}>
                    {page[index].original.Mails_Message}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-3/12`}>
                    {page[index].original.Mails_PartnersType &&
                      page[index].original.Mails_PartnersType}
                  </td>

                  <td className={`text-sm py-4 w-1/12`}>
                    {page[index].original.Mails_IsSent ? (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-10 h-10 text-lg font-bold text-green-800 bg-green-300 border-2 border-green-400 rounded-full cursor-default">
                          <BsSendCheckFill
                            size={25}
                            title="Archivo enviado al cliente"
                          />
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-10 h-10 text-lg font-bold text-red-800 bg-red-300 border-2 border-red-400 rounded-full cursor-default">
                          <BsSendXFill
                            size={25}
                            title="Archivo no enviado al cliente"
                          />
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <MailsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

MailsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
