import { useState } from 'react'

// Components
import Menu from 'components/layout/Menu/Menu'

// Logo Afopa
// import Logo from 'components/ui/Logo/Logo'

// Logo Aula Vilafranca
import Logo from 'components/ui/Logo/LogoAula'

// import Profile from './Profile/Profile'
import Notifications from './Notifications/Notifications'

// Icons
import CloseMenuIcon from 'components/icons/CloseMenuIcon'
import MobileMenuIcon from 'components/icons/MobileMenuIcon'
// import SearchIcon from 'components/icons/SearchIcon'

export default function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <nav className="bg-gray-100 border-green-600 sticky top-0 z-50 print:hidden">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-2">
        {/* <div className="flex items-center justify-between h-24"> */}{' '}
        {/* Si logo = LogoAula */}
        <div className="flex items-center justify-between h-24">
          {' '}
          {/* Si logo = Logo */}
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Logo classes="w-16 h-16" link="/" color="white" />
            </div>
            <div className="text-green-600 text-xl font-bold">
              {' '}
              &nbsp;{' '}
              <span className="text-black text-2xl  ">
                Aula d&apos;Extensió Universitària per a la Gent Gran de
                Vilafranca del Penedès
              </span>{' '}
            </div>
          </div>
          <div className="hidden md:block">
            <div className="flex justify-end items-baseline space-x-4">
              {/* <div className="relative mx-auto text-gray-600">
                <input
                  className=" bg-white h-10 px-5 pr-10 w-80 rounded-lg text-sm focus:outline-none"
                  type="text"
                  name="search"
                  placeholder="Buscar..."
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 mt-3 mr-4"
                >
                  <SearchIcon className="text-gray-600 h-4 w-4 fill-current" />
                </button>
              </div> */}
            </div>
          </div>
          <div className="hidden md:block">
            {/* <div className="ml-4 flex items-center md:ml-6">
              <Notifications device="desktop" />              
              <div className="ml-3 relative">
                <div>
                  <Profile device="desktop" />
                </div>
              </div>
            </div> */}
          </div>
          <div className="-mr-2 flex md:hidden">
            {/* Mobile menu button */}

            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none 
             "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={handleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {!mobileMenu ? (
                <MobileMenuIcon className="h-8 w-8" />
              ) : (
                <CloseMenuIcon className="h-8 w-8" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={`md:hidden ${!mobileMenu && 'hidden'}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Menu device="mobile" handleMobileMenu={handleMobileMenu} />
        </div>
        <div className="pt-4 pb-3 border-t border-red-600">
          <div className="flex items-center px-5">
            {/* <Profile device="mobile" handleMobileMenu={handleMobileMenu} /> */}
            <Notifications device="mobile" />
          </div>
        </div>
      </div>
    </nav>
  )
}
