import PropTypes from 'prop-types'

// Images
import logoGestinet from 'assets/img/logo-gestinet.png'

export default function LogoGestinet({ classes }) {
  return (
    <a
      href="https://www.gestinet.com"
      target="_BLANK"
      rel="noreferrer"
      className="print:hidden"
    >
      <img className={classes} src={logoGestinet} alt="Gestinet" />
    </a>
  )
}
LogoGestinet.propTypes = {
  classes: PropTypes.string.isRequired,
  link: PropTypes.string
}
