// HOOKS
import { Link } from 'wouter'
import { useContext } from 'react'

// CONTEXTS
import { TitleContext } from 'contexts/TitleContext'

export default function Title() {
  const { title } = useContext(TitleContext)

  return (
    <div className="flex items-center justify-start w-full h-8">
      <div className="flex items-center w-full px-4 mx-auto">
        <h1 className="mr-4 text-sm text-black 2xl:text-2xl font-ms-bold">
          {title.name}
        </h1>
      </div>
      {title.buttons && (
        <div className="w-full mx-auto py-1 pr-2 text-right titleRightButton">
          {title.buttons.map((button) => {
            return button.id === 'btnBack' ? (
              <Link to={button.link} key={button.name}>
                <button
                  title="TORNAR"
                  type="button"
                  className="ml-2 h-full font-semibold transition-colors duration-150 bg-red-100 border-2 border-red-600 text-red-600 rounded focus:shadow-outline hover:bg-red-600 hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            ) : (
              <Link to={button.link} key={button.name}>
                <button
                  type="button"
                  className="ml-2 h-full font-semibold transition-colors duration-150 bg-green-100 border-2 border-green-600 text-green-600 rounded focus:shadow-outline hover:bg-green-600 hover:text-white"
                >
                  {button.name}
                </button>
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}
