import { ModalContext } from 'contexts/ModalContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import {
  AiOutlineDelete,
  AiOutlinePrinter,
  AiOutlineSave
} from 'react-icons/ai'
import { FaUserAlt } from 'react-icons/fa'
import Select from 'react-select'

// TODO: Improve printing
function handlePrintSheet() {
  window.print()
}

const CouplesFormDetail = ({ handleSubmit, handleDelete, fields, isNew }) => {
  const genderOptions = [
    { label: 'HOME', value: 'HOME', icon: 'Alt-11' },
    { label: 'DONA', value: 'DONA', icon: 'Alt-12' }
  ]

  const groupOptions = [
    { label: 'GRUP 1', value: 'GRUP 1', icon: 'Alt-11' },
    { label: 'GRUP 2', value: 'GRUP 2', icon: 'Alt-12' }
  ]

  // Modal confirmación eliminación:
  const { openModal } = useContext(ModalContext)
  const handleDeleteModal = (id) => {
    const modalData = {
      id: id,
      handleDelete: handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form
            className="w-full space-y-4 print:py-0 print:m-0 print:space-y-8 print:px-6"
            encType="multipart/form-data"
          >
            <div className="grid w-full grid-cols-4 gap-6 p-4 m-0 border border-gray-300 rounded-sm shadow print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              {/* Campo 'Poblacio': */}
              <div title="POBLACIÓ" className="items-center col-span-1">
                <div className="flex flex-row items-center mb-3 print:m-0">
                  <p
                    className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                    htmlFor="Town"
                  >
                    Població
                  </p>

                  <ErrorMessage
                    name="Town"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Town && errors.Town
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                  name="Town"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* Campo 'C.P.': */}
              <div title="CODI POSTAL" className="items-center col-span-1">
                <div className="flex flex-row items-center mb-3 print:m-0">
                  <p
                    className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                    htmlFor="PostalCode"
                  >
                    C.P.
                  </p>

                  <ErrorMessage
                    name="PostalCode"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.PostalCode && errors.PostalCode
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                  name="PostalCode"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* Campo 'Adreça': */}
              <div title="ADREÇA" className="items-center col-span-1">
                <div className="flex flex-row items-center mb-3 print:m-0">
                  <p
                    className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                    htmlFor="Address"
                  >
                    Adreça
                  </p>

                  <ErrorMessage
                    name="Address"
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Address && errors.Address
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                  name="Address"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>

              {/* Campo 'Grup': */}
              <div title="GRUP" className="items-center col-span-1">
                <div className="flex flex-row items-center mb-3 print:m-0">
                  <p
                    className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                    htmlFor="Grupo"
                  >
                    Grup
                  </p>

                  <ErrorMessage
                    name="Grupo"
                    placeholder="Escriure..."
                    render={(message) => (
                      <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                        {message}
                      </span>
                    )}
                  />
                </div>

                <Select
                  className="w-full rounded-sm text-grey-dark print:hidden"
                  name="Grupo"
                  onChange={(e) => setFieldValue('Grupo', e.value)}
                  placeholder="Seleccionar..."
                  options={groupOptions}
                  value={groupOptions.find(
                    (option) => option.value === values.Grupo
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '2px 0px 2px 0px',
                      border: state.isFocused
                        ? '1px solid #9ca3af'
                        : '1px solid #d1d5db',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      borderRadius: '0.125rem',
                      cursor: 'pointer'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: '#333333',
                      backgroundColor: state.isFocused && '#d1d5db',
                      '&:hover': {
                        backgroundColor: '#f3f4f6'
                      },
                      cursor: 'pointer'
                    })
                  }}
                />

                <Field
                  className={`hidden print:block w-full text-xs pl-0 pt-2 pb-2.5 border-b border-aulagreen`}
                  type="text"
                  value={
                    groupOptions?.find(
                      (option) => option.value === values.Grupo
                    )?.label
                  }
                />
              </div>
            </div>

            <hr className="hidden h-px my-8 bg-gray-200 border-0 print:block"></hr>

            {/* Person 1: */}
            <div className="grid grid-cols-1 p-4 m-0 border border-gray-300 rounded-sm shadow print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              <div className="grid w-full grid-cols-12 gap-6">
                <div className="grid grid-cols-2 col-span-8 gap-6">
                  {/* Campo 'Nom': */}
                  <div
                    title="NOM PERSONA 1"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Name1"
                      >
                        Nom (1)
                      </p>

                      <ErrorMessage
                        name="Name1"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Name1 && errors.Name1
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Name1"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Cognoms': */}
                  <div
                    title="COGNOMS PERSONA 1"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Surnames1"
                      >
                        Cognoms (1)
                      </p>

                      <ErrorMessage
                        name="Surnames1"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Surnames1 && errors.Surnames1
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Surnames1"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'DNI': */}
                  <div
                    title="DNI PERSONA 1"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="DNI1"
                      >
                        DNI (1)
                      </p>

                      <ErrorMessage
                        name="DNI1"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.DNI1 && errors.DNI1
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="DNI1"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Núm. S.S.': */}
                  <div
                    title="NÚMERO SEGURETAT SOCIAL PERSONA 1"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Num_SS_1"
                      >
                        Núm. S.S. (1)
                      </p>

                      <ErrorMessage
                        name="Num_SS_1"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Num_SS_1 && errors.Num_SS_1
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Num_SS_1"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Observacions': */}
                  <div
                    title="OBSERVACIONS PERSONA 1"
                    className="items-center col-span-2"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Studies1"
                      >
                        Observacions (1)
                      </p>

                      <ErrorMessage
                        name="Studies1"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`h-24 pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey align-top 
                      ${
                        touched.Studies1 && errors.Studies1
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      } print:pl-0 print:border print:rounded-sm-none print:border-t-0 print:border-r-0 print:border-l-0 print:resize-none
                      print:text-xs print:border-aulagreen`}
                      as="textarea"
                      name="Studies1"
                      value={values.Studies1 ?? ''}
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>
                </div>

                <div
                  title="FOTO DE PERFIL PERSONA 1"
                  className="flex flex-col items-center justify-center col-span-4"
                >
                  <div className="items-center flex-grow w-full pt-2 print:flex">
                    <FaUserAlt
                      size={100}
                      className="w-auto h-full p-1 m-auto bg-white rounded-full print:w-36 print:h-36 ring-1 ring-gray-300 print:ring-green-700"
                    />
                  </div>

                  <div className="grid w-full grid-cols-2 gap-6">
                    {/* Campo 'Gènere': */}
                    <div className="items-center col-span-2">
                      <div className="flex flex-row items-center mb-3 print:m-0">
                        <p
                          className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                          htmlFor="Gender1"
                        >
                          Gènere (1)
                        </p>

                        <ErrorMessage
                          name="Gender1"
                          render={(message) => (
                            <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <Select
                        className="w-full rounded-sm text-grey-dark print:hidden"
                        name="Gender1"
                        onChange={(e) => setFieldValue('Gender1', e.value)}
                        placeholder="Seleccionar..."
                        options={genderOptions}
                        value={genderOptions.find(
                          (option) => option.value === values.Gender1
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2px 0px 2px 0px',
                            border: state.isFocused
                              ? '1px solid #9ca3af'
                              : '1px solid #d1d5db',
                            boxShadow: state.isFocused ? 'none' : 'none',
                            '&:hover': {
                              border: '1px solid #9ca3af'
                            },
                            borderRadius: '0.125rem',
                            cursor: 'pointer'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: '#333333',
                            backgroundColor: state.isFocused && '#d1d5db',
                            '&:hover': {
                              backgroundColor: '#f3f4f6'
                            },
                            cursor: 'pointer'
                          })
                        }}
                      />

                      <Field
                        className={`hidden print:block w-full text-xs pl-0 pt-2 pb-2.5 border-b border-aulagreen`}
                        type="text"
                        value={
                          genderOptions?.find(
                            (option) => option.value === values.Gender1
                          )?.label
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid w-full grid-cols-4 gap-6 pt-6 print:pt-6">
                {/* Campo 'Mòbil': */}
                <div
                  title="MÒBIL PERSONA 1"
                  className="items-center col-span-1"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="MobilePhone1"
                    >
                      Mòbil (1)
                    </p>

                    <ErrorMessage
                      name="MobilePhone1"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.MobilePhone1 && errors.MobilePhone1
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="MobilePhone1"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>

                {/* Campo 'Tlf.': */}
                <div
                  title="TELÈFON FIXE PERSONA 1"
                  className="items-center col-span-1"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="Phone1"
                    >
                      Tlf. (1)
                    </p>

                    <ErrorMessage
                      name="Phone1"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Phone1 && errors.Phone1
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="Phone1"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>

                {/* Campo 'Correu': */}
                <div
                  title="CORREU PERSONA 1"
                  className="items-center col-span-2"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="Email1"
                    >
                      Correu (1)
                    </p>

                    <ErrorMessage
                      name="Email1"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Email1 && errors.Email1
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="Email1"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>
              </div>
            </div>

            <hr className="hidden h-px my-8 bg-gray-200 border-0 print:block"></hr>

            {/* Person 2: */}
            <div className="grid grid-cols-1 p-4 m-0 border border-gray-300 rounded-sm shadow print:p-0 bg-gray-50 print:bg-white print:border-0 print:shadow-none">
              <div className="grid w-full grid-cols-12 gap-6">
                <div className="grid grid-cols-2 col-span-8 gap-6">
                  {/* Campo 'Nom': */}
                  <div
                    title="NOM PERSONA 2"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Name2"
                      >
                        Nom (2)
                      </p>

                      <ErrorMessage
                        name="Name2"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Name2 && errors.Name2
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Name2"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Cognoms': */}
                  <div
                    title="COGNOMS PERSONA 2"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Surnames2"
                      >
                        Cognoms (2)
                      </p>

                      <ErrorMessage
                        name="Surnames2"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Surnames2 && errors.Surnames2
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Surnames2"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'DNI': */}
                  <div
                    title="DNI PERSONA 2"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="DNI2"
                      >
                        DNI (2)
                      </p>

                      <ErrorMessage
                        name="DNI2"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.DNI2 && errors.DNI2
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="DNI2"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Núm. S.S.': */}
                  <div
                    title="NÚMERO SEGURETAT SOCIAL PERSONA 2"
                    className="items-center col-span-1"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Num_SS_2"
                      >
                        Núm. S.S. (2)
                      </p>

                      <ErrorMessage
                        name="Num_SS_2"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                      ${
                        touched.Num_SS_2 && errors.Num_SS_2
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      }`}
                      name="Num_SS_2"
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>

                  {/* Campo 'Observacions': */}
                  <div
                    title="OBSERVACIONS PERSONA 2"
                    className="items-center col-span-2"
                  >
                    <div className="flex flex-row items-center mb-3 print:m-0">
                      <p
                        className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                        htmlFor="Studies2"
                      >
                        Observacions (2)
                      </p>

                      <ErrorMessage
                        name="Studies2"
                        render={(message) => (
                          <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`h-24 pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey align-top 
                      ${
                        touched.Studies2 && errors.Studies2
                          ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                          : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                      } print:pl-0 print:border print:rounded-sm-none print:border-t-0 print:border-r-0 print:border-l-0 print:resize-none
                      print:text-xs print:border-aulagreen`}
                      as="textarea"
                      name="Studies2"
                      value={values.Studies2 ?? ''}
                      type="text"
                      placeholder="Escriure..."
                    />
                  </div>
                </div>

                <div
                  title="FOTO DE PERFIL PERSONA 2"
                  className="flex flex-col items-center justify-center col-span-4"
                >
                  <div className="items-center flex-grow w-full pt-2 pb-6 print:h-full print:flex">
                    <FaUserAlt
                      size={100}
                      className="w-auto h-full p-1 m-auto bg-white rounded-full print:w-36 print:h-36 ring-1 ring-gray-300 print:ring-green-700"
                    />
                  </div>

                  <div className="grid w-full grid-cols-2 gap-6">
                    {/* Campo 'Gènere': */}
                    <div className="items-center col-span-2">
                      <div className="flex flex-row items-center mb-3 print:m-0">
                        <p
                          className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                          htmlFor="Gender2"
                        >
                          Gènere (2)
                        </p>

                        <ErrorMessage
                          name="Gender2"
                          render={(message) => (
                            <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <Select
                        className="w-full rounded-sm text-grey-dark print:hidden"
                        name="Gender2"
                        onChange={(e) => setFieldValue('Gender2', e.value)}
                        placeholder="Seleccionar..."
                        options={genderOptions}
                        value={genderOptions.find(
                          (option) => option.value === values.Gender2
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2px 0px 2px 0px',
                            border: state.isFocused
                              ? '1px solid #9ca3af'
                              : '1px solid #d1d5db',
                            boxShadow: state.isFocused ? 'none' : 'none',
                            '&:hover': {
                              border: '1px solid #9ca3af'
                            },
                            borderRadius: '0.125rem',
                            cursor: 'pointer'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: '#333333',
                            backgroundColor: state.isFocused && '#d1d5db',
                            '&:hover': {
                              backgroundColor: '#f3f4f6'
                            },
                            cursor: 'pointer'
                          })
                        }}
                      />

                      <Field
                        className={`hidden print:block w-full text-xs pl-0 pt-2 pb-2.5 border-b border-aulagreen`}
                        type="text"
                        value={
                          genderOptions?.find(
                            (option) => option.value === values.Gender2
                          )?.label
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid w-full grid-cols-4 gap-6 pt-6 print:pt-6">
                {/* Campo 'Mòbil': */}
                <div
                  title="MÒBIL PERSONA 2"
                  className="items-center col-span-1"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="MobilePhone2"
                    >
                      Mòbil (2)
                    </p>

                    <ErrorMessage
                      name="MobilePhone2"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.MobilePhone2 && errors.MobilePhone2
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="MobilePhone2"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>

                {/* Campo 'Tlf.': */}
                <div
                  title="TELÈFON FIXE PERSONA 2"
                  className="items-center col-span-1"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="Phone2"
                    >
                      Tlf. (2)
                    </p>

                    <ErrorMessage
                      name="Phone2"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Phone2 && errors.Phone2
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="Phone2"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>

                {/* Campo 'Correu': */}
                <div
                  title="CORREU PERSONA 2"
                  className="items-center col-span-2"
                >
                  <div className="flex flex-row items-center mb-3 print:m-0">
                    <p
                      className="mr-2 text-base font-bold text-green-700 font-ms-semi print:m-0 print:p-0"
                      htmlFor="Email2"
                    >
                      Correu (2)
                    </p>

                    <ErrorMessage
                      name="Email2"
                      render={(message) => (
                        <span className="text-sm font-bold leading-4 text-red-600 print:hidden">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey
                    ${
                      touched.Email2 && errors.Email2
                        ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                        : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                    }`}
                    name="Email2"
                    type="text"
                    placeholder="Escriure..."
                  />
                </div>
              </div>
            </div>

            {/* Botones */}
            <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4 print:hidden">
              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                <button
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm hover:border-green-300 hover:bg-green-200 hover:text-green-700 font-ms-semi"
                  type="submit"
                  alt="GUARDAR"
                  title="GUARDAR"
                >
                  <AiOutlineSave size={25} className="mr-2" />
                  <p className="text-base font-semibold">Guardar</p>
                </button>
              </div>

              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 border rounded-sm border-amber-700 bg-amber-600 font-ms-semi hover:border-amber-300 hover:bg-amber-200 hover:text-amber-700"
                    type="button"
                    alt="IMPRIMIR"
                    title="IMPRIMIR"
                    onClick={handlePrintSheet}
                  >
                    <AiOutlinePrinter size={25} className="mr-2" />
                    <p className="text-base font-semibold">Imprimir</p>
                  </button>
                )}
              </div>

              <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
                {!isNew && (
                  <button
                    className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                    type="button"
                    alt="ELIMINAR"
                    title="ELIMINAR"
                    onClick={() => {
                      handleDeleteModal(values.Id)
                    }}
                  >
                    <AiOutlineDelete size={25} className="mr-2" />
                    <p className="text-base font-semibold">Eliminar</p>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

CouplesFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool
}

export default CouplesFormDetail
