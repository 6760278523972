// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import AdminTable from 'components/ui/Table/AdminTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function AdminCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Columnas:
  const columns = [
    {
      Header: 'Usuari',
      accessor: 'Admin_Name',
      Filter: ColumnFilter
    },
    {
      Header: 'Nom',
      accessor: 'Admin_UserName',
      Filter: ColumnFilter
    }
  ]

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Admin_Id: item.Id,
          Admin_UserName: item.UserName,
          Admin_Name: item.Name,
          Admin_Password: item.Password,
          Admin_Email: item.Email
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      {getTableData.length > 0 && (
        <AdminTable
          data={getTableData}
          columns={columns}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}

AdminCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
