import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

import useFetch from 'hooks/useFetch'

import { UserContext } from 'contexts/UserContext'

import Logo from 'components/ui/Logo/Logo'
import LogoGestinet from 'components/ui/Logo/LogoGestinet'

import OfficeLogin from 'components/login/OfficeLogin'
import OperatorLogin from 'components/login/OperatorLogin'

export default function Login() {
  // Get user context
  const { setUser } = useContext(UserContext)

  // Router (to do redirects)
  const [, setLocation] = useLocation()

  // Form login data
  const [loginData, loginAction] = useFetch()

  // Form operator data
  const [loginOperatorData, loginOperatorAction] = useFetch()

  // Trigger login data (to set userContext and redirect)
  useEffect(() => {
    // If Login OK
    if (loginData.data) {
      // Set user info to User context (context + localstorage)
      setUser(loginData.data)

      // Redirect
      setLocation('/')
    }
    // If Login Operator OK
    if (loginOperatorData.data) {
      // Set user operator info to User context (context + localstorage)
      setUser(loginOperatorData.data)

      // Redirect
      setLocation('/')
    }
  }, [loginData, loginOperatorData])

  const handleSubmitOfficeLogin = async (values) => {
    const loginAPIParams = {
      url: 'auth',
      method: 'POST',
      body: values,
      messageKo: 'Usuari i/o contrasenya incorrectes'
    }
    // Fetch api Login
    loginAction(loginAPIParams)
  }

  const handleSubmitOperatorLogin = (pin) => {
    const loginOperatorAPIParams = {
      url: 'auth/operator',
      method: 'POST',
      body: { pin: pin },
      messageKo: 'PIN incorrecte'
    }
    // Fetch api Login Operator
    loginOperatorAction(loginOperatorAPIParams)
  }

  return (
    <div className="bg-gray-50 py-7 md:h-screen flex justify-center items-center">
      <div>
        <div className="text-center mb-6">
          <Logo classes="m-auto" link="/login" />
        </div>

        <div className="flex items-center justify-center flex-wrap">
          <OfficeLogin
            loading={loginData.loading}
            error={loginData.error}
            handleSubmitOfficeLogin={handleSubmitOfficeLogin}
          />

          {/* <OperatorLogin
            loading={loginOperatorData.loading}
            error={loginOperatorData.error}
            handleSubmitOperatorLogin={handleSubmitOperatorLogin}
          /> */}
        </div>
        <div className="text-center mt-6">
          <LogoGestinet classes="w-28 m-auto" />
        </div>
      </div>
    </div>
  )
}
