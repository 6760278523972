import { useContext, useEffect } from 'react'

import { UserContext } from 'contexts/UserContext'

export default function Logout() {
  // Get user context
  const { setUser } = useContext(UserContext)

  // If Logout load
  useEffect(() => {
    setUser({})
  }, [])

  return <h2>Tancant sessió...</h2>
}
