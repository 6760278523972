// PropTypes & React HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import SinglesTableBar from './SinglesTableBar'

export default function AllUsersTable({ data, columns, handleClick }) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state

  return (
    <div className="flex flex-col">
      <div className="inline-block min-w-full align-middle">
        {/* Tabla: */}
        <table
          className="min-w-full border-2 border-green-600 table-auto"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-green-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-green-600 divide-dashed"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="py-2 text-white hover:bg-green-500 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="ml-4 text-sm text-left">
                      {column.render('Header')}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-green-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-green-200
                    ${index % 2 === 0 && 'bg-green-50'}`}
                >
                  <td className={`text-sm font-bold pl-4 py-4 w-2/12`}>
                    {page[index].original.Singles_Name}
                  </td>

                  <td className={`text-sm font-bold pl-4 py-4 w-2/12`}>
                    {page[index].original.Singles_Surnames}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
                    {page[index].original.Singles_Phone}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
                    {page[index].original.Singles_Email}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-1/12`}>
                    {page[index].original.Singles_Grupo}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-1/12`}>
                    {page[index].original.Singles_PostalCode}
                  </td>

                  <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
                    {page[index].original.Singles_Town}
                  </td>
                </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <SinglesTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

AllUsersTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
