// Ruta app
import PropTypes from 'prop-types'
import { useLocation, Link } from 'wouter'
import { useState, useEffect } from 'react'

// Data
import { menuItems } from 'data/menuItems'

// Icons
import dashboardIcon from 'assets/icons/iconDashboard.svg'
// import entityIcon from 'assets/icons/iconEntity.svg'
import singleIcon from 'assets/icons/iconSingle.svg'
import coupleIcon from 'assets/icons/iconCouple.svg'
import adminIcon from 'assets/icons/headphones.png'
import homeIcon from 'assets/icons/home.svg'
import iconKey from 'assets/icons/iconKey.png'
import iconMail from 'assets/icons/mail.png'
import iconTicket from 'assets/icons/tag-icon.svg'
import SinglesCompGrid from 'components/Singles/SinglesCompGrid'
import { id } from 'date-fns/locale'

// Component:
export default function TagComp({
  handleGetAllTags,
  allTagsState,
  storeTagName
}) {
  // useEffect() para que cuando se haga la llamada a la API, en el componente padre 'TagViewGrid.js', para las etiquetas correspondientes
  // y se recogan los datos los guardemos en una variable useState().
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // Una vez guardados los datos que nos llegan de la API llamamos a la función 'renderArray()' para introducir los datos en la tabla
  // con id 'tableTags'. Esta tabla es invisible, solo la usaremos a la hora de imprimir.
  useEffect(() => {
    if (allTagsState !== null && allTagsState !== undefined) {
      renderArrayInTable()
    }
  }, [allTagsState])

  // Se deben imprimir 3 columnas y 8 filas por página, sin márgenes laterales pero con márgenes arriba y abajo de 0.85cm.
  // ----------------------------------------------------------------------------------------------------------------------
  // Con esta función creamos una filas personalizada 3 registros en cada fila para la tabla con id 'tableTags'.
  function renderArrayInTable() {
    // Cada vez que se pulse un botón para imprimir etiquetas borraremos el 'tBody' de la tabla, crearemos uno nuevo y se lo añadiremos.
    // Porque sinó cada vez que se quieran imprimir etiquetas se acumulan las filas en la tabla y no se borran:
    const tableTags = document.getElementById('tableTags')
    const tBody = document.getElementById('tableBody')

    tBody.parentNode.removeChild(tBody)

    const newTbody = document.createElement('tbody')
    newTbody.id = 'tableBody'
    tableTags.appendChild(newTbody)

    // Creamos este iterador para crear 3 registros por fila en la tabla:
    let iteratorSingles = 1
    // Creamos este iterador para que cada 8 filas se rompa la tabla y haga correctamente el salto de página durante la impresión:
    let breakRow = 1
    // Creamos esta variable para guardar el 'id' de la fila y así podamos añadir celdas a la fila:
    let idRow = ''
    // Creamos este iterador para detectar el final del bucle y asñi añadir a la fila las celdas correspondientes para que siempre tenga 3:
    let iteratorAll = 0

    // Si los datos se han recogido con éxito de la API y empezaremos a llenar la tabla:
    if (allTagsState !== null) {
      const longitudArray = allTagsState.length

      // Bucle para crear las filas de la tabla:
      for (const single of allTagsState) {
        // 'iteratorSingles' sirve para detectar en qué elemento del bucle estamos, para saber en qué posición de la fila añadir
        // el registro, cuando sea '1' significa que el registro se posicionará como el primer elemento de la nueva fila:
        if (iteratorSingles === 1) {
          // Creamos un elemento 'tr', es decir, una fila, con sus correspondientes estilos:
          const tr = document.createElement('tr')
          tr.id = single.Id
          tr.key = single.Id
          tr.classList.add('flex')
          tr.classList.add('flex-row')
          tr.classList.add('justify-start')
          tr.classList.add('items-start')
          tr.style.height = '35mm'

          // Guardamos el 'id' de la fila que acabamos de crear para que podamos añadir en esta misma fila el segundo
          // y el tercer registro:
          idRow = single.Id

          // En caso de que la fila que se esté creando sea la octava fila que se mostrará en la página de impresión,
          // obligaremos que se rompa la tabla para hacer un buen salto de página durante la impresión:
          if (breakRow === 8) {
            tr.style.pageBreakAfter = 'always'
            tr.style.pageBreakInside = 'avoid'
            tr.style.pageBreakBefore = 'avoid'
            breakRow = 0
          }

          // Creamos un elemento 'td', es decir, una celda para la fila, con sus correspondientes estilos:
          const td = document.createElement('td')
          td.classList.add('flex')
          td.classList.add('flex-col')
          td.classList.add('justify-center')
          td.classList.add('items-center')
          td.classList.add('m-auto')
          td.style.width = '70mm'

          // Creamos un elemento 'div', es decir, un contenedor que después añadiremos dentro de la celda:
          const textContainer = document.createElement('div')

          // Creamos varios elementos 'p', es decir, párrafos para mostrar la información del registro correspondiente:
          const paragraph1 = document.createElement('p')
          const paragraph2 = document.createElement('p')
          const paragraph3 = document.createElement('p')
          const paragraph4 = document.createElement('p')

          let text1 = ''
          let text2 = ''
          let text3 = ''
          let text4 = ''

          // En caso de que se quieran imprimir etiquetas de 'Individuales' entraremos aquí, en estas etiquetas solo se mostraran 3 campos
          // de información por lo tanto solo crearemos los 3 textos correspondientes:
          if (storeTagName === 'singles') {
            text1 = document.createTextNode(single.Name + ' ' + single.Surnames)
            text2 = document.createTextNode(single.Address)
            text3 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Parejas' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'couples') {
            text1 = document.createTextNode(
              single.Name1 + ' ' + single.Surnames1
            )
            text2 = document.createTextNode(
              single.Name2 + ' ' + single.Surnames2
            )
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Entidades' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'entities') {
            text1 = document.createTextNode(single.NameEntity)
            text2 = document.createTextNode(single.SecondName)
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }

          // Los textos creados anteriormente los añadiremos a los párrafos que hemos creado también anteriormente:
          paragraph1.appendChild(text1)
          paragraph2.appendChild(text2)
          paragraph3.appendChild(text3)
          if (storeTagName !== 'singles') {
            paragraph4.appendChild(text4)
          }

          // Los párrafos los añadimos al contenedor que hemos creado también anteriormente:
          textContainer.appendChild(paragraph1)
          textContainer.appendChild(paragraph2)
          textContainer.appendChild(paragraph3)
          if (storeTagName !== 'singles') {
            textContainer.appendChild(paragraph4)
          }

          // El contenedor lo añadimos a la celda que hemos creado también anteriormente:
          td.appendChild(textContainer)

          // La celda la añadimos a la fila que hemos creado también anteriormente:
          tr.appendChild(td)

          // Y finalmente añadimos esta fila a la tabla:
          document.getElementById('tableBody').appendChild(tr)

          // Sumamos un número a este iterador para que el siguiente registro se guarde en la segunda posición de la fila:
          iteratorSingles = 2

          breakRow++
        }
        // Cuando 'iteratorSingles' sea '2' significa que el registro se posicionará como el segundo elemento de la fila anteriormente creada:
        else if (iteratorSingles === 2) {
          // Recogeremos la fila que hemos creado cuando 'iteratorSingles' era '1':
          const tr = document.getElementById(idRow)

          // Creamos un elemento 'td', es decir, una celda para la fila, con sus correspondientes estilos:
          const td = document.createElement('td')
          td.classList.add('flex')
          td.classList.add('flex-col')
          td.classList.add('justify-center')
          td.classList.add('items-center')
          td.classList.add('m-auto')
          td.style.width = '70mm'

          // Creamos un elemento 'div', es decir, un contenedor que después añadiremos dentro de la celda:
          const textContainer = document.createElement('div')

          // Creamos varios elementos 'p', es decir, párrafos para mostrar la información del registro correspondiente:
          const paragraph1 = document.createElement('p')
          const paragraph2 = document.createElement('p')
          const paragraph3 = document.createElement('p')
          const paragraph4 = document.createElement('p')

          let text1 = ''
          let text2 = ''
          let text3 = ''
          let text4 = ''

          // En caso de que se quieran imprimir etiquetas de 'Individuales' entraremos aquí, en estas etiquetas solo se mostraran 3 campos
          // de información por lo tanto solo crearemos los 3 textos correspondientes:
          if (storeTagName === 'singles') {
            text1 = document.createTextNode(single.Name + ' ' + single.Surnames)
            text2 = document.createTextNode(single.Address)
            text3 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Parejas' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'couples') {
            text1 = document.createTextNode(
              single.Name1 + ' ' + single.Surnames1
            )
            text2 = document.createTextNode(
              single.Name2 + ' ' + single.Surnames2
            )
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Entidades' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'entities') {
            text1 = document.createTextNode(single.NameEntity)
            text2 = document.createTextNode(single.SecondName)
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }

          // Los textos creados anteriormente los añadiremos a los párrafos que hemos creado también anteriormente:
          paragraph1.appendChild(text1)
          paragraph2.appendChild(text2)
          paragraph3.appendChild(text3)
          if (storeTagName !== 'singles') {
            paragraph4.appendChild(text4)
          }

          // Los párrafos los añadimos al contenedor que hemos creado también anteriormente:
          textContainer.appendChild(paragraph1)
          textContainer.appendChild(paragraph2)
          textContainer.appendChild(paragraph3)
          if (storeTagName !== 'singles') {
            textContainer.appendChild(paragraph4)
          }

          // El contenedor lo añadimos a la celda que hemos creado también anteriormente:
          td.appendChild(textContainer)

          // Y finalmente añadimos la celda a la fila que ya tenemos creada también anteriormente:
          tr.appendChild(td)

          // Sumamos un número a este iterador para que el siguiente registro se guarde en la tercera posición de la fila:
          iteratorSingles = 3
        }
        // Cuando 'iteratorSingles' sea '3' significa que el registro se posicionará como el tercer elemento de la fila anteriormente creada:
        else if (iteratorSingles === 3) {
          // Recogeremos la fila que hemos creado cuando 'iteratorSingles' era '1':
          const tr = document.getElementById(idRow)

          // Creamos un elemento 'td', es decir, una celda para la fila, con sus correspondientes estilos:
          const td = document.createElement('td')
          td.classList.add('flex')
          td.classList.add('flex-col')
          td.classList.add('justify-center')
          td.classList.add('items-center')
          td.classList.add('m-auto')
          td.style.width = '70mm'

          // Creamos un elemento 'div', es decir, un contenedor que después añadiremos dentro de la celda:
          const textContainer = document.createElement('div')

          // Creamos varios elementos 'p', es decir, párrafos para mostrar la información del registro correspondiente:
          const paragraph1 = document.createElement('p')
          const paragraph2 = document.createElement('p')
          const paragraph3 = document.createElement('p')
          const paragraph4 = document.createElement('p')

          let text1 = ''
          let text2 = ''
          let text3 = ''
          let text4 = ''

          // En caso de que se quieran imprimir etiquetas de 'Individuales' entraremos aquí, en estas etiquetas solo se mostraran 3 campos
          // de información por lo tanto solo crearemos los 3 textos correspondientes:
          if (storeTagName === 'singles') {
            text1 = document.createTextNode(single.Name + ' ' + single.Surnames)
            text2 = document.createTextNode(single.Address)
            text3 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Parejas' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'couples') {
            text1 = document.createTextNode(
              single.Name1 + ' ' + single.Surnames1
            )
            text2 = document.createTextNode(
              single.Name2 + ' ' + single.Surnames2
            )
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }
          // En caso de que se quieran imprimir etiquetas de 'Entidades' entraremos aquí, en estas etiquetas se mostraran 4 campos
          // de información por lo tanto crearemos los 4 textos correspondientes:
          else if (storeTagName === 'entities') {
            text1 = document.createTextNode(single.NameEntity)
            text2 = document.createTextNode(single.SecondName)
            text3 = document.createTextNode(single.Address)
            text4 = document.createTextNode(
              single.PostalCode + ' ' + single.Town
            )
          }

          // Los textos creados anteriormente los añadiremos a los párrafos que hemos creado también anteriormente:
          paragraph1.appendChild(text1)
          paragraph2.appendChild(text2)
          paragraph3.appendChild(text3)
          if (storeTagName !== 'singles') {
            paragraph4.appendChild(text4)
          }

          // Los párrafos los añadimos al contenedor que hemos creado también anteriormente:
          textContainer.appendChild(paragraph1)
          textContainer.appendChild(paragraph2)
          textContainer.appendChild(paragraph3)
          if (storeTagName !== 'singles') {
            textContainer.appendChild(paragraph4)
          }

          // El contenedor lo añadimos a la celda que hemos creado también anteriormente:
          td.appendChild(textContainer)

          // Y finalmente añadimos la celda a la fila que ya tenemos creada también anteriormente:
          tr.appendChild(td)

          // Como ya se han creado 3 registor para esta fila, volvemos a empezar con una nueva fila:
          iteratorSingles = 1
        }

        // Si se detecta que se ha acabado el bucle:
        if (iteratorAll === longitudArray - 1) {
          // Recogeremos la fila en la que nos encontramos en este momento:
          const tr = document.getElementById(idRow)

          // Si la fila solamente tiene 1 celda, crearemos 2 nuevas celdas vacías para que esté equilibrada la impresión
          // y no quede una celda suelta por el medio mal alineada:
          if (iteratorSingles === 2) {
            // Creamos la primera celda con sus correspondientes estilos:
            const td1 = document.createElement('td')
            td1.classList.add('flex')
            td1.classList.add('flex-col')
            td1.classList.add('justify-center')
            td1.classList.add('items-center')
            td1.classList.add('m-auto')
            td1.style.width = '70mm'

            // Creamos la segunda celda con sus correspondientes estilos:
            const td2 = document.createElement('td')
            td2.classList.add('flex')
            td2.classList.add('flex-col')
            td2.classList.add('justify-center')
            td2.classList.add('items-center')
            td2.classList.add('m-auto')
            td2.style.width = '70mm'

            // Añadimos las celdas a la fila:
            tr.appendChild(td1)
            tr.appendChild(td2)
          }
          // Si la fila solamente tiene 2 celda, crearemos 1 nueva celda vacías para que esté equilibrada la impresión
          // y no queden una celdas sueltas por el medio mal alineadas:
          else if (iteratorSingles === 3) {
            // Creamos la celda con sus correspondientes estilos:
            const td = document.createElement('td')
            td.classList.add('flex')
            td.classList.add('flex-col')
            td.classList.add('justify-center')
            td.classList.add('items-center')
            td.classList.add('m-auto')
            td.style.width = '70mm'

            // Añadimos la celda a la fila:
            tr.appendChild(td)
          }
        }

        // Iterador que usamos para detectar cuando se acaba el bucle:
        iteratorAll = iteratorAll + 1
      }

      // Al acabar de llenar la tabla abriremos el panel de impresión por defecto del navegador para imprimir la tabla:
      window.print()
    }
  }

  return (
    <>
      <div className="grid w-full grid-cols-12 gap-4">
        {/* INDIVIDUALS (VILAFRANCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-100 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={singleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Individuals <br></br> (Vilafranca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes usuaris individuals de Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allSinglesVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* INDIVIDUALS (COMARCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-100 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={singleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Individuals <br></br> (Comarca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes usuaris individuals de la comarca, sense Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allSinglesWithoutVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* INDIVIDUALS (TOTS) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-100 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={singleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Individuals <br></br> (Tots)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes de tots els usuaris individuals.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allSingles')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* INDIVIDUALS (NO CORREU) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-100 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={singleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Individuals <br></br> (No correu)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes usuaris individuals sense correu.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allSinglesNoMail')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* PARELLES (VILAFRANCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-200 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-start-1 xl:col-end-3 h-80 print:hidden">
          <img src={coupleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Parelles <br></br> (Vilafranca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes parelles de Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allCouplesVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* PARELLES (COMARCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-200 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={coupleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Parelles <br></br> (Comarca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes parelles de la comarca, sense Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allCouplesWithoutVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* PARELLES (TOTES) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-200 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={coupleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Parelles <br></br> (Totes)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes de totes les parelles.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allCouples')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* PARELLES (NO CORREU) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-200 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={coupleIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Parelles <br></br> (No correu)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes parelles sense correu.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allCouplesNoMail')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* ENTITATS (VILAFRANCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-300 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-start-1 xl:col-end-3 h-80 print:hidden">
          <img src={homeIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Entitats <br></br> (Vilafranca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes entitats de Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allEntitiesVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        {/* ENTITATS (COMARCA) */}
        <div className="relative flex flex-col col-span-12 p-4 bg-gray-300 rounded-sm shadow sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 h-80 print:hidden">
          <img src={homeIcon} className="mb-3 w-7 h-7" alt="icon" />

          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-green-700">
            Entitats <br></br> (Comarca)
          </h5>

          <p className="mb-3 font-normal text-gray-500">
            Etiquetes entitats de la comarca, sense Vilafranca.
          </p>

          <div className="flex justify-center w-full mt-auto">
            <button
              type="button"
              onClick={() => handleGetAllTags('allEntitiesWithoutVilafranca')}
              className="w-5/6 px-2 py-1 font-bold text-white bg-green-600 rounded-sm hover:bg-green-400"
            >
              Imprimir
            </button>
          </div>
        </div>

        <table
          id="tableTags"
          className="hidden w-screen gap-0 font-bold print:block print:m-0 print:p-0"
          style={{ fontSize: '10px' }}
        >
          <tbody id="tableBody" className="w-screen p-0 m-0"></tbody>
        </table>
      </div>
    </>
  )
}

TagComp.propTypes = {
  handleGetAllTags: PropTypes.func,
  allTagsState: PropTypes.object,
  storeTagName: PropTypes.string
}
