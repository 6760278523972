import { ModalContext } from 'contexts/ModalContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineDelete, AiOutlineSave } from 'react-icons/ai'
import { BiMailSend } from 'react-icons/bi'
import { FiPaperclip } from 'react-icons/fi'
import { MdAlternateEmail, MdOpenInNew } from 'react-icons/md'

const MailsFormDetail = ({
  handleSubmit,
  handleSubmitFile,
  handleDelete,
  handleDeleteFile,
  handleSendEmail,
  fields,
  setFields,
  setExitOnSubmit
}) => {
  const [focusedBtn, setFocusedBtn] = useState({
    FocusedItemAllEntitiesPartners: 0,
    FocusedItemAllEntitiesPartnersVilafranca: 0,
    FocusedItemAllEntitiesPartnersNoVilafranca: 0,
    FocusedItemAllSinglesPartners: 0,
    FocusedItemAllSinglesPartnersVilafranca: 0,
    FocusedItemAllSinglesPartnersNoVilafranca: 0,
    FocusedItemAllCouplesPartners: 0,
    FocusedItemAllCouplesPartnersVilafranca: 0,
    FocusedItemAllCouplesPartnersNoVilafranca: 0,
    FocusedItemAllPartners: 0
  })
  const [getPartnersType, setPartnersType] = useState([])
  const [disableEntitiesBtns, setDisableEntitiesBtns] = useState(false)
  const [disableAllEntitiesBtns, setDisableAllEntitiesBtns] = useState(false)
  const [disableSinglesBtns, setDisableSinglesBtns] = useState(false)
  const [disableAllSinglesBtns, setDisableAllSinglesBtns] = useState(false)
  const [disableCouplesBtns, setDisableCouplesBtns] = useState(false)
  const [disableAllCouplesBtns, setDisableAllCouplesBtns] = useState(false)
  const [getFileName, setFileName] = useState(null)
  const [getEmail, setEmail] = useState(null)

  const hiddenFileInput = useRef(null)

  const handleClickFile = () => {
    hiddenFileInput.current.click()
  }

  useEffect(async () => {
    if (fields) {
      setPartnersType(fields.PartnersType)

      setFocusedBtn((prevFocusedBtn) => ({
        ...prevFocusedBtn,
        FocusedItemAllPartners: fields.PartnersType?.includes(10) ? 1 : 0,
        FocusedItemAllEntitiesPartners:
          fields.PartnersType?.includes(10) || fields.PartnersType?.includes(1)
            ? 1
            : 0,
        FocusedItemAllEntitiesPartnersVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(1) ||
          fields.PartnersType?.includes(2)
            ? 1
            : 0,
        FocusedItemAllEntitiesPartnersNoVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(1) ||
          fields.PartnersType?.includes(3)
            ? 1
            : 0,
        FocusedItemAllSinglesPartners:
          fields.PartnersType?.includes(10) || fields.PartnersType?.includes(4)
            ? 1
            : 0,
        FocusedItemAllSinglesPartnersVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(4) ||
          fields.PartnersType?.includes(5)
            ? 1
            : 0,
        FocusedItemAllSinglesPartnersNoVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(4) ||
          fields.PartnersType?.includes(6)
            ? 1
            : 0,
        FocusedItemAllCouplesPartners:
          fields.PartnersType?.includes(10) || fields.PartnersType?.includes(7)
            ? 1
            : 0,
        FocusedItemAllCouplesPartnersVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(7) ||
          fields.PartnersType?.includes(8)
            ? 1
            : 0,
        FocusedItemAllCouplesPartnersNoVilafranca:
          fields.PartnersType?.includes(10) ||
          fields.PartnersType?.includes(7) ||
          fields.PartnersType?.includes(9)
            ? 1
            : 0
      }))

      if (fields.PartnersType?.includes(10)) {
        // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
        setDisableAllEntitiesBtns(true)
        setDisableAllSinglesBtns(true)
        setDisableAllCouplesBtns(true)
      } else {
        if (fields.PartnersType?.includes(1)) {
          // Desactivamos los botones la fila de 'Entitats':
          setDisableEntitiesBtns(true)
        }

        if (fields.PartnersType?.includes(4)) {
          // Desactivamos los botones la fila de 'Individuals':
          setDisableSinglesBtns(true)
        }

        if (fields.PartnersType?.includes(7)) {
          // Desactivamos los botones la fila de 'Parelles':
          setDisableCouplesBtns(true)
        }
      }

      if (fields.FileName) {
        setFileName(fields.FileName)
      }
    }
  }, [fields])

  const callSendMail = (values) => {
    handleSendEmail(values, hiddenFileInput)
  }

  // Modal confirmación eliminación:
  const { openModal } = useContext(ModalContext)
  const handleDeleteModal = (id) => {
    const modalData = {
      id: id,
      handleDelete: handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  const handleDeleteFileModal = (id, name) => {
    const modalData = {
      id: name,
      handleDelete: handleDeleteFile,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...fields }}
      onSubmit={(values) => {
        handleSubmit(values, hiddenFileInput)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm
      }) => (
        <Form
          className="grid w-full grid-cols-1 gap-8 m-0"
          encType="multipart/form-data"
        >
          {/* ASSUMPTE + DATA: */}
          <div className="grid justify-between w-full grid-cols-8 gap-x-4">
            <div title="ASSUMPTE" className="items-center w-full col-span-6">
              <label
                className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi"
                htmlFor="Subject"
              >
                Assumpte
              </label>

              <Field
                className="w-full p-2 px-3 pl-2 border border-gray-300 rounded-sm shadow outline-none appearance-none text-grey hover:border-gray-400 focus:border-gray-400"
                name="Subject"
                // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                onChange={(e) => {
                  setFieldValue('Subject', e.target.value)
                  setFields((prevFields) => ({
                    ...prevFields,
                    Subject: e.target.value
                  }))
                }}
                type="text"
                style={{ paddingTop: '9px', paddingBottom: '9px' }}
              />
            </div>

            <div title="DATA" className="items-center w-full col-span-2">
              <label
                className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi"
                htmlFor="DateMail"
              >
                Data
              </label>

              <Field
                className="w-full p-2 px-3 pl-2 border border-gray-300 rounded-sm shadow outline-none appearance-none text-grey hover:border-gray-400 focus:border-gray-400"
                name="DateMail"
                // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                onChange={(e) => {
                  setFieldValue('DateMail', e.target.value)
                  setFields((prevFields) => ({
                    ...prevFields,
                    DateMail: e.target.value
                  }))
                }}
                type="date"
                style={{ paddingTop: '8px', paddingBottom: '8px' }}
              />
            </div>
          </div>

          {/* DESTINATARIS MÚLTIPLES */}
          <div title="DESTINATARIS MÚLTIPLES" className="items-center w-full">
            <label
              className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi col-start-full"
              htmlFor="Partners"
            >
              Destinataris
            </label>

            <div className="grid w-full grid-cols-12 p-3 bg-white border border-gray-300 divide-x divide-green-600 rounded-sm shadow outline-none appearance-none divide-dashed text-grey">
              <div className="grid w-full col-span-3 grid-rows-3 pr-3 gap-y-3">
                {/* SOCIS (TOTS) */}
                <div
                  title="SOCIS (TOTS)"
                  className="flex justify-start w-full col-span-1 row-start-1"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm
                      ${
                        getPartnersType?.includes(10) &&
                        focusedBtn.FocusedItemAllPartners
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array para guardar el 'Id' de cada botón de 'Destinataris' que se seleccione:
                      const partnersType = []
                      // Si el botón 'Socis (Tots)' no está seleccionado, añadimos su 'Id' al array y lo guardamos en la variable 'getPartnersType':
                      if (!focusedBtn.FocusedItemAllPartners) {
                        partnersType?.push(10)
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("Socis - Current fields: ", fields)
                      // console.log("Socis - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllPartners:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllEntitiesPartners:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllEntitiesPartnersVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllEntitiesPartnersNoVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllSinglesPartners:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllSinglesPartnersVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllSinglesPartnersNoVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllCouplesPartners:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllCouplesPartnersVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1,
                        FocusedItemAllCouplesPartnersNoVilafranca:
                          prevFocusedBtn.FocusedItemAllPartners ? 0 : 1
                      }))

                      // Si se selecciona el botón 'Socis (Tots)' desactivaremos todos los demás botones por filas, y si se deselecciona
                      // el botón 'Socis (Tots)' volveremos a activar todos los botones por filas:
                      setDisableAllEntitiesBtns((current) => !current)
                      setDisableAllSinglesBtns((current) => !current)
                      setDisableAllCouplesBtns((current) => !current)

                      // Establecemos las combinaciones de '(Vilafranca)' + '(Comarca)' como deseleccionadas en todas las filas:
                      setDisableEntitiesBtns(false)
                      setDisableSinglesBtns(false)
                      setDisableCouplesBtns(false)
                    }}
                    value={'Socis\n(Tots)'}
                  />
                </div>

                {/* BORRAR */}
                <div
                  title="BORRAR"
                  className="flex justify-start w-full col-span-1 row-start-3"
                >
                  <input
                    className="block w-full col-span-2 px-6 py-2 font-medium text-white bg-red-600 border border-red-700 rounded-sm cursor-pointer font-base hover:border-red-300 hover:bg-red-200 hover:text-red-700"
                    type="button"
                    onClick={() => {
                      // Inicializamos un array para guardar el 'Id' de cada botón de 'Destinataris' que se seleccione. Guardamos un array vacío para limpiar 'Ids' anteriormente seleccionados:
                      const partnersType = []

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("Borrar - Current fields: ", fields)
                      // console.log("Borrar - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en este caso indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllPartners: 0,
                        FocusedItemAllEntitiesPartners: 0,
                        FocusedItemAllEntitiesPartnersVilafranca: 0,
                        FocusedItemAllEntitiesPartnersNoVilafranca: 0,
                        FocusedItemAllSinglesPartners: 0,
                        FocusedItemAllSinglesPartnersVilafranca: 0,
                        FocusedItemAllSinglesPartnersNoVilafranca: 0,
                        FocusedItemAllCouplesPartners: 0,
                        FocusedItemAllCouplesPartnersVilafranca: 0,
                        FocusedItemAllCouplesPartnersNoVilafranca: 0
                      }))

                      // Establecemos todas las filas como deseleccionadas:
                      setDisableAllEntitiesBtns(false)
                      setDisableAllSinglesBtns(false)
                      setDisableAllCouplesBtns(false)

                      // Establecemos las combinaciones de '(Vilafranca)' + '(Comarca)' como deseleccionadas en todas las filas:
                      setDisableEntitiesBtns(false)
                      setDisableSinglesBtns(false)
                      setDisableCouplesBtns(false)
                    }}
                    value={'Borrar'}
                  />
                </div>
              </div>

              <div className="grid w-full grid-cols-3 col-span-9 gap-3 pl-3">
                {/* ENTITATS (TOTES) */}
                <div
                  title="ENTITATS (TOTES)"
                  className="flex justify-start w-full col-start-1"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(1) &&
                          focusedBtn.FocusedItemAllEntitiesPartners) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Entitats (Tots)' no está seleccionado:
                      if (!focusedBtn.FocusedItemAllEntitiesPartners) {
                        // Añadimos el 'Id' del botón 'Entitats (Tots)':
                        partnersType = partnersType.filter(
                          (partner) => partner !== 2 && partner !== 3
                        )
                        partnersType.push(1)

                        // Comprobamos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' significa que se seleccionan todos los 'Destinataris'
                        // por lo tanto seleccionaremos también el botón 'Socis (Tots)'
                        if (
                          partnersType?.includes(4) &&
                          partnersType?.includes(7) &&
                          !partnersType?.includes(10)
                        ) {
                          partnersType = []
                          partnersType?.push(10)

                          // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                          setDisableAllEntitiesBtns(true)
                          setDisableAllSinglesBtns(true)
                          setDisableAllCouplesBtns(true)
                        }
                      } else {
                        partnersType = partnersType.filter(
                          (partner) => partner !== 1
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("ENTITATS (TOTES) - Current fields: ", fields)
                      // console.log("ENTITATS (TOTES) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllEntitiesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))

                      // Si seleccionamos el botón 'Entitats (Totes)', establecemos los botones 'Entitats (Vilafranca)' y 'Entitats (Comarca)'
                      // como seleccionados, en cambio, si deseleccionamos el botón 'Entitats (Totes)', establecemos los botones
                      // 'Entitats (Vilafranca)' y 'Entitats (Comarca)' como deseleccionados:
                      setDisableEntitiesBtns((current) => !current)
                    }}
                    disabled={disableAllEntitiesBtns}
                    value={'Entitats\n(Totes)'}
                  />
                </div>

                {/* ENTITATS (VILAFRANCA) */}
                <div
                  title="ENTITATS (VILAFRANCA)"
                  className="flex justify-center w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(1) &&
                          focusedBtn.FocusedItemAllEntitiesPartners) ||
                        (getPartnersType?.includes(2) &&
                          focusedBtn.FocusedItemAllEntitiesPartnersVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // console.log('1. ', partnersType)
                      // console.log('2. ', getPartnersType)

                      // Si el botón 'Entitats (Vilafranca)' no está seleccionado:
                      if (
                        !focusedBtn.FocusedItemAllEntitiesPartnersVilafranca
                      ) {
                        // Añadimos el 'Id' del botón 'Entitats (Vilafranca)':
                        partnersType?.push(2)

                        // Si está seleccionado también 'Entitats (Comarca)' seleccionaremos 'Entitats (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(3)) {
                          if (
                            partnersType?.includes(4) &&
                            partnersType?.includes(7) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 2 && partner !== 3
                            )
                            partnersType?.push(1)
                            setDisableEntitiesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 2
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("ENTITATS (VILAFRANCA) - Current fields: ", fields)
                      // console.log("ENTITATS (VILAFRANCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // console.log('3. ', partnersType)
                      // console.log('4. ', getPartnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllEntitiesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1) ||
                          partnersType?.includes(2)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1) ||
                          partnersType?.includes(3)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableEntitiesBtns || disableAllEntitiesBtns}
                    value={'Entitats\n(Vilafranca)'}
                  />
                </div>

                {/* ENTITATS (COMARCA) */}
                <div
                  title="ENTITATS (COMARCA)"
                  className="flex justify-end w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(1) &&
                          focusedBtn.FocusedItemAllEntitiesPartners) ||
                        (getPartnersType?.includes(3) &&
                          focusedBtn.FocusedItemAllEntitiesPartnersNoVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // console.log('1. ', partnersType)
                      // console.log('2. ', getPartnersType)

                      // Si el botón 'Entitats (Comarca)' no está seleccionado:
                      if (
                        !focusedBtn.FocusedItemAllEntitiesPartnersNoVilafranca
                      ) {
                        // Añadimos el 'Id' del botón 'Entitats (Comarca)':
                        partnersType?.push(3)

                        // Si está seleccionado también 'Entitats (Individuals)' seleccionaremos 'Entitats (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(2)) {
                          if (
                            partnersType?.includes(4) &&
                            partnersType?.includes(7) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 2 && partner !== 3
                            )
                            partnersType?.push(1)
                            setDisableEntitiesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 3
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("ENTITATS (COMARCA) - Current fields: ", fields)
                      // console.log("ENTITATS (COMARCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // console.log('3. ', partnersType)
                      // console.log('4. ', getPartnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllEntitiesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1) ||
                          partnersType?.includes(2)
                            ? 1
                            : 0,
                        FocusedItemAllEntitiesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(1) ||
                          partnersType?.includes(3)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableEntitiesBtns || disableAllEntitiesBtns}
                    value={'Entitats\n(Comarca)'}
                  />
                </div>

                {/* INDIVIDUALS (TOTS) */}
                <div
                  title="INDIVIDUALS (TOTS)"
                  className="flex justify-start w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(4) &&
                          focusedBtn.FocusedItemAllSinglesPartners) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Individuals (Tots)' no está seleccionado:
                      if (!focusedBtn.FocusedItemAllSinglesPartners) {
                        // Añadimos el 'Id' del botón 'Individuals (Tots)':
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 5 && partner !== 6
                        )
                        partnersType.push(4)

                        // Comprobamos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' significa que se seleccionan todos los 'Destinataris'
                        // por lo tanto seleccionaremos también el botón 'Socis (Tots)'
                        if (
                          partnersType?.includes(1) &&
                          partnersType?.includes(7) &&
                          !partnersType?.includes(10)
                        ) {
                          partnersType = []
                          partnersType?.push(10)

                          // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                          setDisableAllEntitiesBtns(true)
                          setDisableAllSinglesBtns(true)
                          setDisableAllCouplesBtns(true)
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 4
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("INDIVIDUALS (TOTS) - Current fields: ", fields)
                      // console.log("INDIVIDUALS (TOTS) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllSinglesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))

                      // Si seleccionamos el botón 'Individuals (Totes)', establecemos los botones 'Individuals (Vilafranca)'
                      // y 'Individuals (Comarca)' como seleccionados, en cambio, si deseleccionamos el botón 'Individuals (Totes)',
                      // establecemos los botones 'Individuals (Vilafranca)' y 'Individuals (Comarca)' como deseleccionados:
                      setDisableSinglesBtns((current) => !current)
                    }}
                    disabled={disableAllSinglesBtns}
                    value={'Individuals\n(Tots)'}
                  />
                </div>

                {/* INDIVIDUALS (VILAFRANCA) */}
                <div
                  title="INDIVIDUALS (VILAFRANCA)"
                  className="flex justify-center w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(4) &&
                          focusedBtn.FocusedItemAllSinglesPartners) ||
                        (getPartnersType?.includes(5) &&
                          focusedBtn.FocusedItemAllSinglesPartnersVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Individuals (Vilafranca)' no está seleccionado:
                      if (!focusedBtn.FocusedItemAllSinglesPartnersVilafranca) {
                        // Añadimos el 'Id' del botón 'Individuals (Vilafranca)':
                        partnersType.push(5)

                        // Si está seleccionado también 'Individuals (Comarca)' seleccionaremos 'Individuals (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(6)) {
                          if (
                            partnersType?.includes(1) &&
                            partnersType?.includes(7) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 5 && partner !== 6
                            )
                            partnersType?.push(4)
                            setDisableSinglesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 5
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("INDIVIDUALS (VILAFRANCA) - Current fields: ", fields)
                      // console.log("INDIVIDUALS (VILAFRANCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllSinglesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4) ||
                          partnersType?.includes(5)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4) ||
                          partnersType?.includes(6)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableSinglesBtns || disableAllSinglesBtns}
                    value={'Individuals\n(Vilafranca)'}
                  />
                </div>

                {/* INDIVIDUALS (COMARCA) */}
                <div
                  title="INDIVIDUALS (COMARCA)"
                  className="flex justify-end w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(4) &&
                          focusedBtn.FocusedItemAllSinglesPartners) ||
                        (getPartnersType?.includes(6) &&
                          focusedBtn.FocusedItemAllSinglesPartnersNoVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Individuals (Vilafranca)' no está seleccionado:
                      if (
                        !focusedBtn.FocusedItemAllSinglesPartnersNoVilafranca
                      ) {
                        // Añadimos el 'Id' del botón 'Individuals (Vilafranca)':
                        partnersType?.push(6)

                        // Si está seleccionado también 'Individuals (Vilafranca)' seleccionaremos 'Individuals (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(5)) {
                          if (
                            partnersType?.includes(1) &&
                            partnersType?.includes(7) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 5 && partner !== 6
                            )
                            partnersType?.push(4)
                            setDisableSinglesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 6
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("INDIVIDUALS (COMARCA) - Current fields: ", fields)
                      // console.log("INDIVIDUALS (COMARCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllSinglesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4) ||
                          partnersType?.includes(5)
                            ? 1
                            : 0,
                        FocusedItemAllSinglesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(4) ||
                          partnersType?.includes(6)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableSinglesBtns || disableAllSinglesBtns}
                    value={'Individuals\n(Comarca)'}
                  />
                </div>

                {/* PARELLES (TOTES) */}
                <div
                  title="PARELLES (TOTES)"
                  className="flex justify-start w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(7) &&
                          focusedBtn.FocusedItemAllCouplesPartners) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Parelles (Tots)' no está seleccionado:
                      if (!focusedBtn.FocusedItemAllCouplesPartners) {
                        // Añadimos el 'Id' del botón 'Parelles (Tots)':
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 8 && partner !== 9
                        )
                        partnersType?.push(7)

                        // Comprobamos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' significa que se seleccionan todos los 'Destinataris'
                        // por lo tanto seleccionaremos también el botón 'Socis (Tots)'
                        if (
                          partnersType?.includes(1) &&
                          partnersType?.includes(4) &&
                          !partnersType?.includes(10)
                        ) {
                          partnersType = []
                          partnersType?.push(10)

                          // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                          setDisableAllEntitiesBtns(true)
                          setDisableAllSinglesBtns(true)
                          setDisableAllCouplesBtns(true)
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 7
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("PARELLES (TOTES) - Current fields: ", fields)
                      // console.log("PARELLES (TOTES) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllCouplesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))

                      // Si seleccionamos el botón 'Parelles (Totes)', establecemos los botones 'Parelles (Vilafranca)' y 'Parelles (Comarca)'
                      // como seleccionados, en cambio, si deseleccionamos el botón 'Parelles (Totes)', establecemos los botones
                      // 'Parelles (Vilafranca)' y 'Parelles (Comarca)' como deseleccionados:
                      setDisableCouplesBtns((current) => !current)
                    }}
                    disabled={disableAllCouplesBtns}
                    value={'Parelles\n(Totes)'}
                  />
                </div>

                {/* PARELLES (VILAFRANCA) */}
                <div
                  title="PARELLES (VILAFRANCA)"
                  className="flex justify-center w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(7) &&
                          focusedBtn.FocusedItemAllCouplesPartners) ||
                        (getPartnersType?.includes(8) &&
                          focusedBtn.FocusedItemAllCouplesPartnersVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Parelles (Vilafranca)' no está seleccionado:
                      if (!focusedBtn.FocusedItemAllCouplesPartnersVilafranca) {
                        // Añadimos el 'Id' del botón 'Parelles (Vilafranca)':
                        partnersType?.push(8)

                        // Si está seleccionado también 'Parelles (Comarca)' seleccionaremos 'Parelles (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(9)) {
                          if (
                            partnersType?.includes(1) &&
                            partnersType?.includes(4) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 8 && partner !== 9
                            )
                            partnersType?.push(7)
                            setDisableCouplesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 8
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("PARELLES (VILAFRANCA) - Current fields: ", fields)
                      // console.log("PARELLES (VILAFRANCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllCouplesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7) ||
                          partnersType?.includes(8)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7) ||
                          partnersType?.includes(9)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableCouplesBtns || disableAllCouplesBtns}
                    value={'Parelles\n(Vilafranca)'}
                  />
                </div>

                {/* PARELLES (COMARCA) */}
                <div
                  title="PARELLES (COMARCA)"
                  className="flex justify-end w-full"
                >
                  <input
                    className={`w-full block font-base font-medium py-2 px-6 border rounded-sm disabled:opacity-50 disabled:cursor-not-allowed
                      ${
                        (getPartnersType?.includes(7) &&
                          focusedBtn.FocusedItemAllCouplesPartners) ||
                        (getPartnersType?.includes(9) &&
                          focusedBtn.FocusedItemAllCouplesPartnersNoVilafranca) ||
                        (getPartnersType?.includes(10) &&
                          focusedBtn.FocusedItemAllPartners)
                          ? 'border-green-400 bg-green-200 hover:border-green-600 hover:bg-green-200 hover:text-green-700 cursor-pointer'
                          : 'border-green-600 bg-grey-50 text-green-700 cursor-pointer hover:bg-green-200'
                      }`}
                    type="button"
                    onClick={() => {
                      // Inicializamos un array en el que guardamos el array con 'Ids':
                      let partnersType =
                        getPartnersType !== null && getPartnersType?.length > 0
                          ? [...getPartnersType]
                          : []

                      // Si el botón 'Parelles (Comarca)' no está seleccionado:
                      if (
                        !focusedBtn.FocusedItemAllCouplesPartnersNoVilafranca
                      ) {
                        // Añadimos el 'Id' del botón 'Parelles (Comarca)':
                        partnersType?.push(9)

                        // Si está seleccionado también 'Parelles (Vilafranca)' seleccionaremos 'Parelles (Tots)',
                        // pero primero comprobaremos si tenemos que seleccionar el botón 'Socis (Tots)', si el botón 'Entitats (Tots)',
                        // el botón 'Individuals (Tots)' y el botón 'Parelles (Tots)' están seleccionados significa que se seleccionan
                        // todos los 'Destinataris', por lo tanto seleccionaremos el botón 'Socis (Tots)':
                        if (partnersType?.includes(8)) {
                          if (
                            partnersType?.includes(1) &&
                            partnersType?.includes(4) &&
                            !partnersType?.includes(10)
                          ) {
                            partnersType = []
                            partnersType?.push(10)

                            // Desactivamos las 3 filas de botones ('1. Entitats', '2. Individuals', '3. Parelles') para evitar problemas:
                            setDisableAllEntitiesBtns(true)
                            setDisableAllSinglesBtns(true)
                            setDisableAllCouplesBtns(true)
                          } else {
                            partnersType = partnersType?.filter(
                              (partner) => partner !== 8 && partner !== 9
                            )
                            partnersType?.push(7)
                            setDisableCouplesBtns(true)
                          }
                        }
                      } else {
                        partnersType = partnersType?.filter(
                          (partner) => partner !== 9
                        )
                      }

                      // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                      // console.log("PARELLES (COMARCA) - Current fields: ", fields)
                      // console.log("PARELLES (COMARCA) - New value: ", partnersType)
                      setFieldValue('PartnersType', partnersType)
                      setFields((prevFields) => ({
                        ...prevFields,
                        PartnersType: partnersType
                      }))
                      setPartnersType(partnersType)

                      // Establecemos para cada botón una variable que especifique si debe marcar un botón como seleccionado o no,
                      // en caso de seleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como seleccionados,
                      // porque por lógica seleccionar este botón es igual a seleccionar todos los demás. En el caso contrario, es decir,
                      // en caso de deseleccionar el botón 'Socis (Tots)' indicaremos que se marquen todos los botones como no seleccionados:
                      setFocusedBtn((prevFocusedBtn) => ({
                        ...prevFocusedBtn,
                        FocusedItemAllCouplesPartners:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7) ||
                          partnersType?.includes(8)
                            ? 1
                            : 0,
                        FocusedItemAllCouplesPartnersNoVilafranca:
                          partnersType?.includes(10) ||
                          partnersType?.includes(7) ||
                          partnersType?.includes(9)
                            ? 1
                            : 0,
                        FocusedItemAllPartners: partnersType?.includes(10)
                          ? 1
                          : 0
                      }))
                    }}
                    disabled={disableCouplesBtns || disableAllCouplesBtns}
                    value={'Parelles\n(Comarca)'}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* DESTINATARI INDIVIDUAL */}
          <div title="DESTINATARI INDIVIDUAL" className="items-center w-full">
            <label
              className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi"
              htmlFor="IndividualPartner"
            >
              Destinatari Individual
            </label>

            <div className="flex flex-row items-center w-full p-2 px-3 pl-2 bg-white border border-gray-300 rounded-sm shadow outline-none appearance-none text-grey hover:border-gray-400 focus:border-gray-400">
              <div className="flex items-center justify-center h-full pl-1 pr-3 border-r-2 border-gray-300 border-dashed">
                <MdAlternateEmail size={20} color="grey" />
              </div>

              <Field
                name="IndividualPartner"
                type="email"
                className="flex-grow block pl-3 text-gray-900 rounded-sm focus:border-none focus:outline-none"
                // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
                onChange={(e) => {
                  const inputEmail = e.target.value.trim()
                  setEmail(inputEmail)

                  setFieldValue('IndividualPartner', e.target.value)
                  setFields((prevFields) => ({
                    ...prevFields,
                    IndividualPartner: e.target.value
                  }))
                }}
                onKeyDown={(e) => {
                  const validEndings = [
                    '.com',
                    '.net',
                    '.org',
                    '.edu',
                    '.gov',
                    '.mil',
                    '.int',
                    '.biz',
                    '.info',
                    '.name',
                    '.pro',
                    '.coop',
                    '.museum',
                    '.aero',
                    '.jobs',
                    '.travel',
                    '.cat',
                    '.es',
                    '.de',
                    '.fr',
                    '.uk',
                    '.au',
                    '.ca',
                    '.jp',
                    '.cn',
                    '.ru',
                    '.br',
                    '.mx'
                  ]

                  const validEmailPattern = new RegExp(
                    `^[^@\\s]+@[^@\\s]+(${validEndings
                      .map((ending) => `\\${ending}`)
                      .join('|')})$`,
                    'i'
                  )

                  const isValidEmail = validEmailPattern.test(getEmail)

                  const isBackspaceKey = e.code === 'Backspace'
                  const isCommaKey = e.code === 'Space'
                  const isSpaceKey = e.code === 'Comma'
                  const isPeriodKey = e.code === 'Period'

                  const emailParts = getEmail?.split('@')
                  const emailDomain = emailParts ? emailParts[1] : null

                  const inputElement = e.target
                  if (isValidEmail && !isBackspaceKey) {
                    e.preventDefault()
                  }

                  if (isCommaKey || isSpaceKey) {
                    e.preventDefault()
                  }

                  if (
                    isPeriodKey &&
                    getEmail.includes('@') &&
                    emailDomain.includes('.')
                  ) {
                    e.preventDefault()
                  }
                }}
              />
            </div>
          </div>

          {/* MISSATGE */}
          <div title="MISSATGE" className="items-center w-full">
            <label
              className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi"
              htmlFor="Message"
            >
              Missatge
            </label>

            <Field
              className="block w-full p-2 px-3 pl-2 border border-gray-300 rounded-sm shadow outline-none appearance-none h-80 text-grey hover:border-gray-400 focus:border-gray-400"
              name="Message"
              // He añadido 'setFieldValue' y 'setFields' en el 'onChange' para evitar la pérdida de valores al adjuntar o eliminar documentos:
              onChange={(e) => {
                setFieldValue('Message', e.target.value)
                setFields((prevFields) => ({
                  ...prevFields,
                  Message: e.target.value
                }))
              }}
              type="text"
              component="textarea"
            />
          </div>

          {/* Adjunts */}
          <div title="ADJUNTS" className="items-center w-full">
            <label
              className="block mb-2 mr-2 text-base font-bold text-green-700 font-ms-semi"
              htmlFor="Message"
            >
              Adjunts
            </label>

            <div className="relative w-full overflow-x-auto border border-gray-300 rounded-sm shadow">
              <table className="w-full text-left text-gray-500">
                <tbody className="divide-y divide-gray-300 divide-dashed">
                  {values?.FileName !== null &&
                  values?.FileName !== undefined &&
                  values?.FileName !== 'null' &&
                  values?.FileName !== '' &&
                  values?.FileName.length > 0 ? (
                    values?.FileName?.map((name) => (
                      <tr className="bg-white" key={name}>
                        <td className="p-3 whitespace-nowrap font-ms-semi">
                          <div className="flex flex-row justify-between w-full">
                            <a
                              title={name}
                              href={values?.FilePath?.find((path) =>
                                path.endsWith(encodeURI(name))
                              )}
                              className="flex items-center p-2 space-x-2 text-black transition duration-300 rounded-sm cursor-pointer w-min flew-row hover:bg-gray-200"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <span>
                                <MdOpenInNew size={24} />
                              </span>
                              <span>{name}</span>
                            </a>

                            <button
                              alt="ELIMINAR DOCUMENT"
                              title="ELIMINAR DOCUMENT"
                              className="flex flex-row p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                              type="button"
                              onClick={() => {
                                handleDeleteFileModal(values.Id, name)
                              }}
                            >
                              <AiOutlineDelete size={25} className="mr-2" />
                              <p className="text-base font-semibold">
                                ELIMINAR DOCUMENT
                              </p>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white">
                      <th className="px-6 py-5 text-base font-semibold whitespace-nowrap">
                        Aquest mail no té documents adjunts.
                      </th>
                    </tr>
                  )}
                </tbody>

                <tfoot className="w-full bg-gray-100 border-t border-gray-300">
                  <tr className="font-semibold text-gray-900">
                    <th className="p-3 text-sm whitespace-nowrap font-ms-semi ">
                      <div className="flex justify-center w-full">
                        <button
                          alt={`${
                            values.Id === 'new'
                              ? 'AFEGIR DOCUMENT I GUARDAR MAIL'
                              : 'AFEGIR I GUARDAR DOCUMENT'
                          }`}
                          title={`${
                            values.Id === 'new'
                              ? 'AFEGIR DOCUMENT I GUARDAR MAIL'
                              : 'AFEGIR I GUARDAR DOCUMENT'
                          }`}
                          className="flex flex-row items-center justify-center p-2 text-white transition duration-300 bg-gray-600 border border-gray-700 rounded-sm hover:border-gray-300 hover:bg-gray-200 hover:text-gray-700 font-ms-semi"
                          type="button"
                          onClick={handleClickFile}
                        >
                          <FiPaperclip size={20} className="mr-2" />
                          <p className="text-base font-semibold">
                            {values.Id === 'new'
                              ? 'AFEGIR DOCUMENT I GUARDAR MAIL'
                              : 'AFEGIR I GUARDAR DOCUMENT'}
                          </p>
                        </button>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <input
              ref={hiddenFileInput}
              type="file"
              className="hidden"
              onChange={(e) => {
                if (e?.currentTarget?.files?.length > 0) {
                  handleSubmitFile(values, e)
                }
              }}
            />
          </div>

          {/* Botones */}
          <div className="grid w-full grid-cols-12 m-0 gap-y-4">
            {/* Guardar */}
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              <button
                alt="GUARDAR MAIL"
                title="GUARDAR MAIL"
                className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm hover:border-green-300 hover:bg-green-200 hover:text-green-700 font-ms-semi"
                type="button"
                onClick={() => {
                  setExitOnSubmit(false)
                  submitForm()
                }}
              >
                <AiOutlineSave size={25} className="mr-2" />
                <p className="text-base font-semibold">GUARDAR MAIL</p>
              </button>
            </div>

            {/* Guardar i sortir */}
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              <button
                alt="GUARDAR MAIL I SORTIR"
                title="GUARDAR MAIL I SORTIR"
                className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm hover:border-green-300 hover:bg-green-200 hover:text-green-700 font-ms-semi"
                type="button"
                onClick={() => {
                  setExitOnSubmit(true)
                  submitForm()
                }}
              >
                <AiOutlineSave size={25} className="mr-2" />
                <p className="text-base font-semibold">GUARDAR MAIL I SORTIR</p>
              </button>
            </div>

            {/* Enviar */}
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              {values.Id !== 'new' && (
                <button
                  alt="GUARDAR I ENVIAR MAIL"
                  title="GUARDAR I ENVIAR MAIL"
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 border rounded-sm border-amber-700 bg-amber-600 font-ms-semi hover:border-amber-300 hover:bg-amber-200 hover:text-amber-700"
                  onClick={() => {
                    setExitOnSubmit(true)

                    const convertedValues = {
                      Id: values.Id,
                      DateMail: values.DateMail ? values.DateMail : '',
                      Subject: values.Subject ? values.Subject : '',
                      Message: values.Message ? values.Message : '',
                      IndividualPartner: values.IndividualPartner,
                      Partners: '',
                      PartnersType: values.PartnersType
                        ? values.PartnersType
                        : [],
                      FileName: getFileName,
                      FilePath: values.FilePath ? values.FilePath : null
                    }

                    callSendMail(convertedValues)
                  }}
                  type="button"
                >
                  <BiMailSend size={25} className="mr-2" />
                  <p className="text-base font-semibold">
                    GUARDAR I ENVIAR MAIL
                  </p>
                </button>
              )}
            </div>

            {/* Eliminar */}
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              {values.Id !== 'new' && (
                <button
                  className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                  type="button"
                  alt="ELIMINAR MAIL"
                  title="ELIMINAR MAIL"
                  onClick={() => {
                    handleDeleteModal(values.Id)
                  }}
                >
                  <AiOutlineDelete size={25} className="mr-2" />
                  <p className="text-base font-semibold">ELIMINAR MAIL</p>
                </button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

MailsFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitFile: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  fields: PropTypes.object,
  setFields: PropTypes.func,
  setExitOnSubmit: PropTypes.func.isRequired
}

export default MailsFormDetail
