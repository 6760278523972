// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import EntitiesFormDetail from 'forms/Entities/EntitiesFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading.gif'

export default function EntitiesViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params.id === 'new'
  const type = params.type

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Entitat`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/entities/grid${
          !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
        }`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Entitat`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/entities/grid${
          !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
        }`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    NameEntity: '',
    SecondName: '',
    Address: '',
    PostalCode: '',
    Town: '',
    Phone: '',
    Phone2: '',
    Email: '',
    Observations: '',
    EntityType: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `entities/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        NameEntity: getApiCallGet.data.NameEntity,
        SecondName: getApiCallGet.data.SecondName,
        Address: getApiCallGet.data.Address,
        PostalCode: getApiCallGet.data.PostalCode,
        Town: getApiCallGet.data.Town,
        Phone: getApiCallGet.data.Phone,
        Phone2: getApiCallGet.data.Phone2,
        Email: getApiCallGet.data.Email,
        Observations: getApiCallGet.data.Observations,
        EntityType: getApiCallGet.data.EntityType
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `entities`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation(
          `/entities/grid${
            !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
          }`
        )
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation(
          `/entities/grid${
            !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
          }`
        )
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `entities/${params.id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(
          `/entities/grid${
            !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
          }`
        )
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(
          `/entities/grid${
            !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
          }`
        )
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (isNew && fields.Id === 0) || (!isNew && fields.Id !== 0) ? (
    <EntitiesFormDetail
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco}></img>
        </p>
      </div>
    </div>
  )
}

EntitiesViewDetail.propTypes = {
  params: PropTypes.any
}
