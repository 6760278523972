import Menu from 'components/layout/Menu/Menu'

import LogoGestinet from 'components/ui/Logo/LogoGestinet'

export default function Sidebar() {
  return (
    <>
      {/* // Barra lateral con menú y logo de Gestinet: */}
      {/* <div className="print:hidden flex-col hidden md:flex w-14 md:w-48 text-white flex-shrink-0 bg-green-600 border-r border-green-600 top-0"> */}
      {/* 'div' necesario para poder hacer esta barra fija en la pantalla y así no desaparezca al hacer scroll: */}
      {/* <div className="h-screen w-14 md:w-48 pt-1 fixed text-white bg-green-600 border-r border-green-600"> */}
      {/* Menú: */}
      {/* <nav className="md:block px-4 pb-4 md:pb-0 md:overflow-y-auto fixed">
            <Menu device="desktop" /> */}
      {/* </nav> */}
      {/* Logo: */}
      {/* <LogoGestinet classes="w-24 m-auto mb-6 fixed bottom-0 left-12" />
        </div>
      </div> */}

      <aside
        id="sidebar-multi-level-sidebar"
        className="print:hidden fixed flex flex-col top-0 left-0 z-40 w-64 h-screen divide-y divide-dashed divide-green-900 transition-transform -translate-x-full sm:translate-x-0 bg-green-600"
        aria-label="Sidebar"
      >
        <div
          id="sidebar-menu"
          className="flex-grow w-full h-14 px-3 py-4 overflow-y-auto scroll-smooth pt-28"
        >
          <Menu device="desktop" />
        </div>

        <div className="w-full bg-green-900">
          <LogoGestinet classes="w-20 py-4 m-auto" />
        </div>
      </aside>
    </>
  )
}
