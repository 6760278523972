import { useContext, useEffect, useState } from 'react'
import { TitleContext } from 'contexts/TitleContext'
import { useLocation } from 'wouter'
import TagComp from 'components/Tag/TagComp.js'
import useFetch from 'hooks/useFetch'
import loadingIco from 'components/ui/Logo/loading.gif'

export default function TagViewGrid(params) {
  // WOUTER y CONTEXT
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  const [fetchedAllTagsData, setFetchAllTagsData] = useFetch()
  const [storeTagName, setStoreTagName] = useState()

  // En esta función están todas las llamadas a la API dentro del 'switch' y dependiendo del parámetro que nos llega por la
  // función haremos una llamada a la API u otra.
  // ------------------------------------------------------------------------------------------------------------------------
  // Enviamos la variable 'getAllTags' al componente hijo 'TagComp.js', una vez allí, dependiendo del botón
  // que se pulse se llamará esta misma función con un parámetro específico que decidirá la llamada a la API que se deba realizar.
  // ------------------------------------------------------------------------------------------------------------------------
  // Al llamar a la API recogeremos los datos y los guardaremos en la variable 'fetchedAllTagsData' que también enviamos
  // al componente hijo 'TagComp.js' para que éste procese la información y la muestre en una tabla preparada para ser imprimida.
  // Al guardar esta variable con un 'useState()' cuando se modifique el valor de la variable en este componente, también se modificará en
  // el componente hijo 'TagComp.js'.
  const getAllTags = async (tagType) => {
    switch (tagType) {
      // En caso que quieran etiquetas de todas las Entidades de la comarca sin Vilafranca:
      case 'allEntitiesWithoutVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('entities')

        // Parámetros para llamar a la API:
        const TagsEntitiesNoVilafrancaAPIParams = {
          url: 'tags/entities-no-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Entitats fora de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsEntitiesNoVilafrancaAPIParams)
        break
      }
      // En caso que quieran etiquetas de todas las Entidades de Vilafranca:
      case 'allEntitiesVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('entities')

        // Parámetros para llamar a la API:
        const TagsEntitiesVilafrancaAPIParams = {
          url: 'tags/entities-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Entitats de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsEntitiesVilafrancaAPIParams)
        break
      }
      // En caso que quieran etiquetas de todos los usuarios individuales:
      case 'allSingles': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('singles')

        // Parámetros para llamar a la API:
        const AllTagsSinglesAPIParams = {
          url: 'tags/singles',
          method: 'GET',
          messageKo: 'Error carregant llista Individuals'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(AllTagsSinglesAPIParams)
        break
      }
      // En caso que quieran etiquetas de todos los usuarios individuales sin correu:
      case 'allSinglesNoMail': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('singles')

        // Parámetros para llamar a la API:
        const TagsSinglesNoMailAPIParams = {
          url: 'tags/singles-no-mail',
          method: 'GET',
          messageKo: 'Error carregant llista Individuals sense correu'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsSinglesNoMailAPIParams)
        break
      }
      // En caso que quieran etiquetas de todos los usuarios individuales de Vilafranca:
      case 'allSinglesVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('singles')

        // Parámetros para llamar a la API:
        const TagsSinglesVilafrancaAPIParams = {
          url: 'tags/singles-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Individuals de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsSinglesVilafrancaAPIParams)
        break
      }
      // En caso que quieran etiquetas de todos los usuarios individuales fuera de Vilafranca:
      case 'allSinglesWithoutVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('singles')

        // Parámetros para llamar a la API:
        const TagsSinglesNoVilafrancaAPIParams = {
          url: 'tags/singles-no-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Individuals fora de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsSinglesNoVilafrancaAPIParams)
        break
      }
      // En caso que quieran etiquetas de todas las parejas:
      case 'allCouples': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('couples')

        // Parámetros para llamar a la API:
        const AllTagsCouplesAPIParams = {
          url: 'tags/couples',
          method: 'GET',
          messageKo: 'Error carregant llista Parelles'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(AllTagsCouplesAPIParams)
        break
      }
      // En caso que quieran etiquetas de todas las parejas sin correo:
      case 'allCouplesNoMail': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('couples')

        // Parámetros para llamar a la API:
        const TagsCouplesNoMailAPIParams = {
          url: 'tags/couples-no-mail',
          method: 'GET',
          messageKo: 'Error carregant llista Parelles sense correu'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsCouplesNoMailAPIParams)
        break
      }
      // En caso que quieran etiquetas de todas las parejas de Vilafranca:
      case 'allCouplesVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('couples')

        // Parámetros para llamar a la API:
        const TagsCouplesVilafrancaAPIParams = {
          url: 'tags/couples-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Parelles de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsCouplesVilafrancaAPIParams)
        break
      }
      // En caso que quieran etiquetas de todas las parejas fuera de Vilafranca:
      case 'allCouplesWithoutVilafranca': {
        // 'setStoreTagName' también lo enviamos al componente hijo 'TagComp.js' para determinar las celdas que se crearán en la
        // tabla para imprimir, ya Entidades, Individuales y Parejas tienen distintos campos en la base de datos:
        setStoreTagName('couples')

        // Parámetros para llamar a la API:
        const TagsCouplesNoVilafrancaAPIParams = {
          url: 'tags/couples-no-vilafranca',
          method: 'GET',
          messageKo: 'Error carregant llista Parelles fora de Vilafranca'
        }

        // Llamamos a la API y recogemos los datos:
        await setFetchAllTagsData(TagsCouplesNoVilafrancaAPIParams)
        break
      }
      default:
        break
    }
  }

  // Para poner el título de la página en el header:
  useEffect(() => {
    const tagsViewTitle = {
      name: 'Etiquetes'
    }
    setTitle(tagsViewTitle)
  }, [])

  return (
    <div className="md:flex print:m-0 print:p-0">
      <div className="w-full print:m-0 print:p-0">
        <div className="flex flex-col print:m-0 print:p-0">
          <TagComp
            handleGetAllTags={getAllTags}
            allTagsState={fetchedAllTagsData.data}
            storeTagName={storeTagName}
          />
        </div>
      </div>
    </div>
  )
}
