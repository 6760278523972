// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import AdminFormDetail from 'forms/Admin/AdminFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading.gif'

export default function AdminViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params?.id === 'new'

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Usuari`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/admin/grid`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Usuari`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <ImArrowLeft2 size={20} className="pl-4 pr-2" />{' '}
            <span className="pl-2 pr-4">Tornar</span>
          </span>
        ),
        link: `/admin/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    Name: '',
    UserName: '',
    Password: '',
    Email: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `users/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Id: getApiCallGet.data.Id,
        Name: getApiCallGet.data.Name,
        Email: getApiCallGet.data.Email,
        UserName: getApiCallGet.data.UserName,
        NewPassword: '',
        RepeatNewPassword: ''
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `users`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`

      if (values.NewPassword === '') {
        values.NewPassword = null
        values.RepeatNewPassword = null
      }
    }

    const apiCallPost = {
      url,
      method,
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation('/admin/grid')
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation('/admin/grid')
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async (id) => {
    const apiCallDelete = {
      url: `users/${id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation('/admin/grid')
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation('/admin/grid')
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (isNew && fields.Id === 0) || (!isNew && fields.Id !== 0) ? (
    <AdminFormDetail
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco}></img>
        </p>
      </div>
    </div>
  )
}

AdminViewDetail.propTypes = {
  params: PropTypes.any
}
