// PropTypes & HOOKS
import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

const ModalContextProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [getModalData, setModalData] = useState(null)

  const openModal = (modalData) => {
    setIsModalOpen(true)
    setModalData(modalData)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setModalData(null)
  }

  return (
    <ModalContext.Provider
      value={{ isModalOpen, openModal, closeModal, getModalData }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider

ModalContextProvider.propTypes = {
  children: PropTypes.any
}
