import loadingIco from 'components/ui/Logo/loading.gif'
import { TitleContext } from 'contexts/TitleContext'
import SinglesFormDetail from 'forms/Singles/SinglesFormDetail'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { ImArrowLeft2 } from 'react-icons/im'
import { toast } from 'react-toastify'
import { useLocation } from 'wouter'

export default function SinglesViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params.id === 'new'
  const type = params.type

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Individual`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="w-full flex flex-row items-center">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link:
          type === 'all'
            ? `/all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Individual`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="w-full flex flex-row items-center">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link:
          type === 'all'
            ? `/all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    Name: '',
    Surnames: '',
    Town: '',
    BirthDate: '',
    Address: '',
    Email: '',
    MobilePhone: '',
    Phone: '',
    Photo: '',
    PostalCode: '',
    Studies: '',
    DNI: '',
    Num_SS: '',
    Gender: '',
    Grupo: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `singles/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Name: getApiCallGet.data.Name,
        Surnames: getApiCallGet.data.Surnames,
        Town: getApiCallGet.data.Town,
        BirthDate: '1950/01/01',
        Address: getApiCallGet.data.Address,
        Email: getApiCallGet.data.Email,
        MobilePhone: getApiCallGet.data.MobilePhone,
        Phone: getApiCallGet.data.Phone,
        Photo: getApiCallGet.data.Photo,
        PostalCode: getApiCallGet.data.PostalCode,
        Studies: getApiCallGet.data.Studies,
        DNI: getApiCallGet.data.DNI,
        Num_SS: getApiCallGet.data.Num_SS,
        Gender: getApiCallGet.data.Gender,
        Grupo: getApiCallGet.data.Grupo
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `singles`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `singles/${params.id}`,
      method: 'DELETE'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(
          type === 'all'
            ? `/all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(
          type === 'all'
            ? `all/grid`
            : `/singles/grid${
                !type ? '' : type === 'vilafranca' ? '/vilafranca' : '/comarca'
              }`
        )
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (isNew && fields.Id === 0) || (!isNew && fields.Id !== 0) ? (
    <SinglesFormDetail
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div>
      <div className="h-full w-full flex justify-center items-center content-center">
        <p
          className="h-full text-green-700 font-extrabold flex justify-center flex-col-reverse items-center"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco}></img>
        </p>
      </div>
    </div>
  )
}

SinglesViewDetail.propTypes = {
  params: PropTypes.any
}
