// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// CONTEXT
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import { MdClose } from 'react-icons/md'
import { BsTrash, BsExclamationTriangle } from 'react-icons/bs'

export default function DeleteModal({ params }) {
  /**
   * @param isModalOpen  - abrir modal
   * @param closeModal   - cerrar modal
   * @param id           - id del elemento que queremos borrar
   * @param handleDelete - función para eliminar
   */
  const { isModalOpen, closeModal } = useContext(ModalContext)
  const { id, handleDelete } = useContext(ModalContext).getModalData

  // Bloqueamos el scroll:
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop)
  }

  const handleConfirm = () => {
    handleDelete(id)
    closeModal()
    window.onscroll = function () {}
  }

  return (
    isModalOpen && (
      <>
        <div
          className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black bg-opacity-75"
          onClick={() => {
            closeModal()
            window.onscroll = function () {}
          }}
        />

        <div className="fixed inset-0 z-50 flex items-center justify-center w-1/2 m-auto overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-full max-w-6xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-2 border-red-600 divide-y divide-red-200 rounded-md shadow-lg outline-none focus:outline-none divide-dashed">
              {/* CABECERA */}
              <div className="flex items-center justify-between p-5">
                <h3 className="text-2xl font-semibold">
                  <b>ELIMINAR</b> Registre
                </h3>
                <button
                  className="flex items-center justify-center px-1 py-1 text-white transition duration-300 bg-red-500 border-2 border-red-600 rounded hover:border-red-400 hover:bg-red-300 hover:text-red-600 font-ms-semi"
                  type="button"
                  onClick={() => {
                    closeModal()
                    window.onscroll = function () {}
                  }}
                >
                  <MdClose size={25} title="Cerrar" />
                </button>
              </div>

              {/* CUERPO */}
              <div className="flex flex-col items-center justify-center w-full px-6 py-12">
                <p className="text-lg font-bold text-red-700">
                  Està segur/a que vol <u className="text-red-900">ELIMINAR</u>{' '}
                  el registre?
                </p>
              </div>

              {/* PIE */}
              <div className="grid grid-cols-1 p-4 border-t border-solid rounded-b border-slate-200">
                <div className="flex items-center justify-end col-span-1 ">
                  <button
                    className="flex items-center justify-center px-6 py-2 text-white transition duration-300 bg-red-500 border-2 border-red-600 rounded hover:border-red-400 hover:bg-red-300 hover:text-red-600 font-ms-semi"
                    type="button"
                    onClick={() => handleConfirm()}
                  >
                    <BsTrash size={30} title="Eliminar" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

DeleteModal.propTypes = {
  params: PropTypes.any
}
