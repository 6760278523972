import PropTypes from 'prop-types'

// Images
import whiteLogo from 'assets/img/logo-trans.png'

export default function LogoAula({ classes }) {
  return (
    <a>
      <img src={whiteLogo} />
    </a>
  )
}
LogoAula.propTypes = {
  classes: PropTypes.string.isRequired,
  link: PropTypes.string
}
