// PropTypes & React HOOKS:
import PropTypes from 'prop-types'
import { useEffect, useContext } from 'react'

// COMPONENTS:
import { TitleContext } from 'contexts/TitleContext'

export default function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useContext(TitleContext)

  useEffect(() => {
    localStorage.setItem(column.id, filterValue)
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (previousFilter !== 'undefined') {
      setFilter(previousFilter)
    }
  }, [title])

  return (
    <>
      {column.Header === 'Enviat' ? (
        <span></span>
      ) : (
        <input
          className={`w-full mt-2 pr-1 border-2 border-gray-200 2xl:text-sm 
              focus:outline-none hover:border-green-300 font-lato rounded-sm py-1 px-1 text-gray-400 text-sm`}
          value={filterValue || ''}
          placeholder={`Filtrar....`}
          onChange={(e) => setFilter(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </>
  )
}

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}
