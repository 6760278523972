// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// HOOKS (Routing)
import { Switch, Route, Redirect } from 'wouter'

// COMPONENTS
import Layout from 'components/layout/Layout'
import Login from 'pages/Login/Login'
import Logout from 'pages/Login/Logout'
import Dashboard from 'pages/Dashboard/Dashboard'
// import Profile from 'pages/Profile/Profile'
import EntitiesViewGrid from 'pages/Entities/Entities_All/EntitiesViewGrid'
import EntitiesVilafrancaViewGrid from 'pages/Entities/Entities_Vilafranca/EntitiesVilafrancaViewGrid'
import EntitiesComarcaViewGrid from 'pages/Entities/Entities_Comarca/EntitiesComarcaViewGrid'
import AllViewGrid from 'pages/AllUsers/AllUsersViewGrid'
import SinglesViewGrid from 'pages/Singles/Singles_All/SinglesViewGrid'
import SinglesVilafrancaViewGrid from 'pages/Singles/Singles_Vilafranca/SinglesVilafrancaViewGrid'
import SinglesComarcaViewGrid from 'pages/Singles/Singles_Comarca/SinglesComarcaViewGrid'
import CouplesViewGrid from 'pages/Couples/Couples_All/CouplesViewGrid'
import CouplesVilafrancaViewGrid from 'pages/Couples/Couples_Vilafranca/CouplesVilafrancaViewGrid'
import CouplesComarcaViewGrid from 'pages/Couples/Couples_Comarca/CouplesComarcaViewGrid'
import AdminViewGrid from 'pages/Admin/AdminViewGrid'
import MailsViewGrid from 'pages/Mails/MailsViewGrid'
import EntitiesViewDetail from 'pages/Entities/Entities_All/EntitiesViewDetail'
import SinglesViewDetail from 'pages/Singles/Singles_All/SinglesViewDetail'
import CouplesViewDetail from 'pages/Couples/Couples_All/CouplesViewDetail'
import AdminViewDetail from 'pages/Admin/AdminViewDetail'
import MailsViewDetail from 'pages/Mails/MailsViewDetail'
import TagViewGrid from './Tag/TagViewGrid'

// CONTEXTS
import { UserContext } from 'contexts/UserContext'

// Rutas privadas que se pueden usar si se ha iniciado sesión:
const PrivateRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? (
    <Layout>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirect to="/login" />
  )

// Ruta 'LOG IN' que se usa si no se ha iniciado sesión:
const LoginRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? <Redirect to="/" /> : <Route path="/login" component={Login} />

export default function App() {
  const { user } = useContext(UserContext)

  const isLoggedIn = user && !!user.token

  return (
    <>
      <Switch>
        {/* <- LOG IN / LOG OUT */}
        <LoginRoute isLoggedIn={isLoggedIn} path="/login" component={Login} />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/logout"
          component={Logout}
        />
        {/* LOG IN / LOG OUT -> */}

        {/* ######################################################################### */}

        {/* <- PANEL */}
        <PrivateRoute isLoggedIn={isLoggedIn} path="/" component={Dashboard} />
        {/* PANEL -> */}

        {/* ######################################################################### */}

        {/* <- ENTIDADES */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/entities/grid"
          component={EntitiesViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/entities/grid/vilafranca"
          component={EntitiesVilafrancaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/entities/grid/comarca"
          component={EntitiesComarcaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/entities/:id/:type?"
          component={EntitiesViewDetail}
        />
        {/* ENTIDADES -> */}

        {/* ######################################################################### */}
        {/* <- TOTS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/all/grid"
          component={AllViewGrid}
        />

        {/* <- INDIVIDUALES */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/grid"
          component={SinglesViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/grid/vilafranca"
          component={SinglesVilafrancaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/grid/comarca"
          component={SinglesComarcaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/:id/:type?"
          component={SinglesViewDetail}
        />
        {/* INDIVIDUALES -> */}

        {/* ######################################################################### */}

        {/* <- PAREJAS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/couples/grid"
          component={CouplesViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/couples/grid/vilafranca"
          component={CouplesVilafrancaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/couples/grid/comarca"
          component={CouplesComarcaViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/couples/:id/:type?"
          component={CouplesViewDetail}
        />
        {/* PAREJAS -> */}

        {/* ######################################################################### */}

        {/* <- USUARIOS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/admin/grid"
          component={AdminViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/admin/:id"
          component={AdminViewDetail}
        />
        {/* USUARIOS -> */}

        {/* ######################################################################### */}

        {/* <- CORREOS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mails/grid"
          component={MailsViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mails/:id"
          component={MailsViewDetail}
        />
        {/* CORREOS -> */}

        {/* ######################################################################### */}

        {/* <- ETIQUETAS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/tags"
          component={TagViewGrid}
        />
        {/* ETIQUETAS -> */}
      </Switch>

      <ToastContainer />
    </>
  )
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}

LoginRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}
